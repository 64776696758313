import { RequireOnlyOne } from '@kindo/universal/src/utils/type.utils';
import React, { useState } from 'react';
import { Button, ButtonSize, ButtonType, Icon, Size, TextIconColor, TypographySize } from '~/components/core';
interface BaseCopyButtonProps {
  buttonType?: ButtonType;
  'data-id'?: string | undefined;
  disabled?: boolean;
  getText?: () => string;
  icon?: Icon;
  label?: string;
  showSuccessTime?: number;
  size?: ButtonSize;
  successText?: string;
  text?: string;
  textIconColor?: TextIconColor | undefined;
  tooltip?: string;
  typographySize?: TypographySize;
}
type CopyButtonProps = RequireOnlyOne<BaseCopyButtonProps, 'text' | 'getText'>;
const CopyButton: React.FC<CopyButtonProps> = ({
  text,
  'data-id': dataId,
  label = 'Copy',
  icon = Icon.COPY,
  textIconColor,
  tooltip,
  size = Size.MEDIUM,
  showSuccessTime = 1000,
  successText = 'Copied',
  buttonType = ButtonType.OUTLINED,
  disabled = false,
  getText = () => text,
  typographySize = TypographySize.SMALL
}) => {
  const [copied, setCopied] = useState(false);
  const copyText = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const textToCopy = text || getText();
    if (textToCopy) {
      await navigator.clipboard.writeText(textToCopy);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, showSuccessTime);
  };
  const displayIcon = copied ? Icon.CHECK : icon;
  return <Button data-id={dataId} disabled={disabled} endIcon={displayIcon} label={copied ? successText : label} onClick={copyText} size={size} textIconColor={textIconColor} tooltip={tooltip} type={buttonType} typographySize={typographySize} />;
};
export default CopyButton;