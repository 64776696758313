import { CSSProperties } from 'react';
export const MARKDOWN_COLORS = {
  background: '#09090D',
  text: '#9694B5',
  border: '#342D89',
  primary: '#4E5CF2',
  comment: '#404045',
  cyan: '#00FAFA',
  blue: '#92C1FB',
  pink: '#F24E7C'
} as const;
type PrismTheme = {
  [key: string]: CSSProperties | {
    [key: string]: CSSProperties;
  };
};
export const kindoCodeTheme: PrismTheme = {
  'pre[class*="language-"]': {
    color: MARKDOWN_COLORS.text,
    background: MARKDOWN_COLORS.background,
    fontFamily: 'var(--font-ibmPlexMono)',
    fontSize: '14px',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    margin: 0,
    padding: '1em',
    overflow: 'auto',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    border: `1px dashed ${MARKDOWN_COLORS.border}`,
    borderTop: 'none'
  },
  'code[class*="language-"]': {
    color: MARKDOWN_COLORS.primary,
    background: 'none',
    fontFamily: 'var(--font-ibmPlexMono)',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    tabSize: 4,
    hyphens: 'none'
  },
  comment: {
    color: MARKDOWN_COLORS.comment
  },
  prolog: {
    color: MARKDOWN_COLORS.comment
  },
  doctype: {
    color: MARKDOWN_COLORS.comment
  },
  cdata: {
    color: MARKDOWN_COLORS.comment
  },
  punctuation: {
    color: MARKDOWN_COLORS.text
  },
  property: {
    color: MARKDOWN_COLORS.primary
  },
  tag: {
    color: MARKDOWN_COLORS.primary
  },
  boolean: {
    color: MARKDOWN_COLORS.cyan
  },
  number: {
    color: MARKDOWN_COLORS.cyan
  },
  constant: {
    color: MARKDOWN_COLORS.cyan
  },
  symbol: {
    color: MARKDOWN_COLORS.cyan
  },
  selector: {
    color: MARKDOWN_COLORS.primary
  },
  'attr-name': {
    color: MARKDOWN_COLORS.primary
  },
  string: {
    color: MARKDOWN_COLORS.blue
  },
  char: {
    color: MARKDOWN_COLORS.blue
  },
  builtin: {
    color: MARKDOWN_COLORS.cyan
  },
  operator: {
    color: MARKDOWN_COLORS.cyan
  },
  entity: {
    color: MARKDOWN_COLORS.primary,
    cursor: 'help'
  },
  url: {
    color: MARKDOWN_COLORS.primary
  },
  variable: {
    color: MARKDOWN_COLORS.text
  },
  '.language-css .token.string': {
    color: MARKDOWN_COLORS.blue
  },
  '.style .token.string': {
    color: MARKDOWN_COLORS.blue
  },
  atrule: {
    color: MARKDOWN_COLORS.cyan
  },
  'attr-value': {
    color: MARKDOWN_COLORS.blue
  },
  keyword: {
    color: MARKDOWN_COLORS.pink
  },
  function: {
    color: MARKDOWN_COLORS.primary
  },
  regex: {
    color: MARKDOWN_COLORS.cyan
  },
  important: {
    color: MARKDOWN_COLORS.cyan,
    fontWeight: 'bold'
  },
  bold: {
    fontWeight: 'bold'
  },
  italic: {
    fontStyle: 'italic'
  },
  deleted: {
    color: MARKDOWN_COLORS.pink
  },
  inserted: {
    color: MARKDOWN_COLORS.blue
  }
};