import _styled from "styled-components";
import { ChatMessageState, LLM_DISPLAY_NAMES } from '@kindo/universal';
import { forwardRef } from 'react';
import { ResponseChatMessage } from '../Chat.types';
import useSmoothStreaming from '../ResponseChatMessage/useSmoothStreaming';
import { Size, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { Markdown } from '~/components/Markdown';
import { ContentReference, CopyButton } from '~/components/shared';
import { useAppSelector } from '~/hooks';
const BatchResponseMessageContainer = _styled.div<{
  $isError?: boolean;
}>(({
  $isError
}) => [{
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.75rem"
}, $isError && {
  "gap": "1.5rem",
  "borderRadius": "0.375rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(248 113 113 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(254 202 202 / var(--tw-bg-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "1.5rem",
  "paddingBottom": "1.5rem"
}, !$isError && {
  "borderRadius": "0 3px 3px 3px",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(141 118 255 / var(--tw-border-opacity))",
  "backgroundColor": "rgba(37, 34, 69, 0.46)",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "--tw-backdrop-blur": "blur(53.5px)",
  "WebkitBackdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
  "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)"
}]);
const ContentAndResponseContainer = _styled.div({
  "display": "flex",
  "width": "100%"
});
const ContentContainer = _styled.div({
  "display": "flex",
  "flex": "1 1 0%",
  "flexDirection": "column",
  "gap": "0.375rem"
});
const ResponseContainer = _styled.div({
  "display": "flex",
  "flex": "3"
});
const ContentReferences = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexWrap": "wrap",
  "gap": "0.5rem"
});
const ModelNameAndMessageContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.375rem"
});
const ActionButtons = _styled.div({
  "display": "flex",
  "width": "100%",
  "justifyContent": "space-between",
  "paddingRight": "1rem"
});
const HiddenButtons = _styled.div({
  "visibility": "hidden",
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem",
  ".group\\/message:hover &": {
    "visibility": "visible"
  }
});
interface BatchResponseMessageProps {
  chatMessage: ResponseChatMessage;
  fileNumber: number;
  totalBatchedResponses: number;
}
const BatchResponseMessage = forwardRef<HTMLDivElement, BatchResponseMessageProps>(({
  chatMessage,
  fileNumber,
  totalBatchedResponses
}, ref) => {
  const {
    isStreaming,
    message,
    model,
    referencedContent,
    state
  } = chatMessage;

  // Redux
  const {
    userId
  } = useAppSelector(({
    user
  }) => user);

  // Custom Hooks
  const {
    text: smoothStreamingText
  } = useSmoothStreaming({
    chatMessage
  });
  const isError = state === ChatMessageState.ERROR;
  return <BatchResponseMessageContainer ref={ref} $isError={isError} className="group/message">
      <ContentAndResponseContainer>
        <ContentContainer>
          <Typography size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
            {`File ${fileNumber} of ${totalBatchedResponses}`}
          </Typography>
          {!!referencedContent?.length && <ContentReferences>
              {referencedContent.map(content => <ContentReference key={content.referenceId} canDownload={content.creatorId === userId} contentId={content.contentId} fileName={content.fileName} nameMaxWidth={{
            "maxWidth": "150px"
          }} source={content.source} tooltip={content.sourceContent} />)}
            </ContentReferences>}
        </ContentContainer>
        <ResponseContainer>
          <ModelNameAndMessageContainer>
            <Typography size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
              {model && LLM_DISPLAY_NAMES[model] || 'AI'}
            </Typography>
            {isStreaming && smoothStreamingText ? <Markdown chatMessageId={chatMessage.id} isStreaming>
                {smoothStreamingText}
              </Markdown> : <Markdown chatMessageId={chatMessage.id} isStreaming={isStreaming}>
                {message}
              </Markdown>}
            {!isError && <ActionButtons>
                <HiddenButtons>
                  <CopyButton size={Size.SMALL} text={message} />
                  {/* TODO: Regenerate message and regenerate entire batch */}
                </HiddenButtons>
              </ActionButtons>}
          </ModelNameAndMessageContainer>
        </ResponseContainer>
      </ContentAndResponseContainer>
    </BatchResponseMessageContainer>;
});
export default BatchResponseMessage;