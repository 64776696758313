import { Size, TextIconColor } from '../constants';
import { Icon, Icons } from '../Icon';
import { Typography, TypographyWeight } from '../Typography';
import { ToggleSize, ToggleState } from './Toggle.consts';
import { StyledInput, StyledToggleDisplay, StyledInnerBox, ToggleContainer } from './Toggle.styles';
import ToggleLoadingSpinner from './ToggleLoadingSpinner';
interface ToggleProps {
  checked: boolean;
  onToggle: (isChecked: boolean) => void;
  disabled?: boolean;
  loading?: boolean;
  size?: ToggleSize;
}
const Toggle: React.FC<ToggleProps> = ({
  checked,
  disabled = false,
  loading = false,
  size = Size.MEDIUM,
  onToggle
}) => <ToggleContainer>
    <StyledInput checked={checked} disabled={disabled} onChange={e => !disabled && !loading && onToggle(e.target.checked)} type="checkbox" />
    <StyledToggleDisplay $checked={checked} $disabled={disabled} $loading={loading} $size={size} aria-checked={checked} role="switch">
      <StyledInnerBox $checked={checked} $disabled={disabled} $size={size}>
        <Typography color={checked ? TextIconColor.BLACK : TextIconColor.PRIMARY} size={11} weight={TypographyWeight.MEDIUM}>
          {checked ? ToggleState.ACTIVE : ToggleState.OFF}
        </Typography>
      </StyledInnerBox>
      {loading && <ToggleLoadingSpinner $checked={checked}>
          <Icons color={TextIconColor.SECONDARY} icon={Icon.LOADING} size={Size.SMALL} />
        </ToggleLoadingSpinner>}
    </StyledToggleDisplay>
  </ToggleContainer>;
export default Toggle;