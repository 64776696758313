import _styled from "styled-components";
import { ChipSize, CHIP_SIZE_STYLES, CHIP_CLOSE_BUTTON_STYLES, CHIP_ICON_STYLES } from './Chip.consts';
export const ChipContainer = _styled.div<{
  $hasClose: boolean;
  $size: ChipSize;
}>(({
  $hasClose,
  $size
}) => [
// Base styles
{
  "position": "relative",
  "display": "inline-flex",
  "cursor": "default"
}, CHIP_SIZE_STYLES[$size], !$hasClose && {
  "paddingRight": "0.75rem"
},
// Background and hover states
{
  "backgroundColor": "rgb(255 255 255 / 0.1)",
  "&:hover": {
    "backgroundColor": "rgb(255 255 255 / 0.2)"
  }
}, {
  "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms"
}]);
export const IconWrapper = _styled.div`
  ${CHIP_ICON_STYLES}
  ${{
  "marginRight": "-3px"
}}

  svg {
    ${{
  "height": "100%",
  "width": "100%",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}}
  }
`;
export const CloseButton = _styled.button`
  ${CHIP_CLOSE_BUTTON_STYLES}
  ${{
  "backgroundColor": "rgb(255 255 255 / 0.3)",
  "&:hover": {
    "backgroundColor": "rgb(255 255 255 / 0.4)"
  }
}}
  
  svg {
    ${{
  "height": "100%",
  "width": "100%",
  "--tw-text-opacity": "1",
  "color": "rgb(37 34 69 / var(--tw-text-opacity))"
}}
  }
`;