import _styled from "styled-components";
import { ChatMessageState, getKindoErrorMessage, isKindoErrorCode, LLM_DISPLAY_NAMES, TOOL_DISPLAY_NAMES, getFileTypeFromMimeType, ChatMessageContentType, FileType, Tool, ChatErrorCode, Llm, SupportedLlm, getRecommendedModelForError } from '@kindo/universal';
import { Button, ButtonType, TextIconColor, Icon, Icons, Size, Typography, TypographySize, TypographyWeight, TypographyCasing, TypographyFont } from '../../core';
import { Markdown } from '../../Markdown';
import { ContentReference } from '../../shared/ContentReference';
import { CopyButton } from '../../shared/CopyButton';
import { ResponseChatMessage } from '../Chat.types';
import CodeExecutionResult from './CodeExecutionResult';
import useSmoothStreaming from './useSmoothStreaming';
import { Dropdown, DropdownItem } from '~/components/Dropdown';
import { CsvViewer } from '~/components/shared/CsvViewer';
import { useAppSelector, useSlackMessageSharing } from '~/hooks';

// For csv-row-by-row, enable tooltip for response and error columns on CSV output
// so that all text is visible
const CSV_COLUMNS_WITH_TOOLTIP = ['response', 'error'];
const ResponseChatMessageContainer = _styled.div<{
  $isError: boolean;
  $isHandledKindoError: boolean;
}>(({
  $isError,
  $isHandledKindoError
}) => [{
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.75rem",
  "backgroundColor": "rgb(9 9 9 / 0.5)"
}, $isError && {
  "gap": "1.5rem",
  "borderRadius": "0.375rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(255 51 108 / var(--tw-border-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "1.5rem",
  "paddingBottom": "1.5rem"
}, $isHandledKindoError && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(253 235 144 / var(--tw-border-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "1.5rem",
  "paddingBottom": "1.5rem"
}, !$isError && {
  "borderRadius": "0 3px 3px 3px",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(141 118 255 / var(--tw-border-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "--tw-backdrop-blur": "blur(53.5px)",
  "WebkitBackdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
  "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)"
}]);
const ContentAndToolReferencesContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexWrap": "wrap",
  "gap": "0.5rem"
});
const CsvViewerContainer = _styled.div({
  "position": "relative",
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "overflow": "auto",
  "borderRadius": "0.25rem",
  "borderWidth": "1px",
  "borderStyle": "dashed",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))",
  "--tw-drop-shadow": "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
  "filter": "var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)"
});
const CsvViewerHeaderContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "borderTopLeftRadius": "0.25rem",
  "borderTopRightRadius": "0.25rem",
  "borderBottomWidth": "1px",
  "borderStyle": "dashed",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
});
const CsvContentReferenceContainer = _styled.div({
  "display": "flex",
  "flexWrap": "wrap",
  "alignItems": "center",
  "gap": "0.5rem"
});
const MessageTextContainer = _styled.div({
  "display": "flex"
});
const ActionButtons = _styled.div({
  "display": "flex",
  "width": "100%",
  "justifyContent": "space-between",
  "paddingRight": "1rem"
});
const HiddenButtons = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem"
});
const ModelNameAndMessageContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.375rem"
});
type ResponseChatMessageProps = {
  chatMessage: ResponseChatMessage;
  onRegenerate: (newModel?: SupportedLlm) => void;
  onStreamChange: () => void;
};
const renderNewModelForErrorButton = (message: string, model: Llm | null, isKindoError: boolean, onRegenerate?: (model: SupportedLlm) => void) => {
  if (!model) return null;
  const recommendedModel = getRecommendedModelForError(message, model, isKindoError);
  if (!recommendedModel) return null;
  return <Button label={`Try with ${LLM_DISPLAY_NAMES[recommendedModel]} instead`} onClick={() => {
    onRegenerate?.(recommendedModel);
  }} size={Size.SMALL} type={ButtonType.OUTLINED} />;
};
const ResponseChatMessageComponent: React.FC<ResponseChatMessageProps> = ({
  chatMessage,
  onRegenerate,
  onStreamChange
}) => {
  // Redux
  const {
    userId
  } = useAppSelector(({
    user
  }) => user);

  // Custom Hooks
  const {
    isSlackConnected,
    slackChannels,
    handleShareToSlack
  } = useSlackMessageSharing();
  const {
    artifacts,
    isStreaming,
    message,
    model,
    referencedContent,
    state,
    workflowStepNumber,
    codeExecution
  } = chatMessage;
  const {
    text: smoothStreamingText
  } = useSmoothStreaming({
    chatMessage,
    onChange: onStreamChange
  });
  const isError = state === ChatMessageState.ERROR;
  const isKindoError = isError && isKindoErrorCode(message);
  const isHandledKindoError = isKindoError && message !== ChatErrorCode.CHAT_UNEXPECTED_ERROR;
  const displayedMessage = isKindoError ? getKindoErrorMessage(message) : message;
  const toolsUsed = new Set(artifacts?.map(artifact => artifact.tool));
  // NOTE: Special case for handling the toolsUsed set when performing a library search.
  // Since vector search source nodes from the library are stored in a similar manner
  // to both specified content (current and historical) and the knowledge store,
  // we need to explicitly check for the presence of a LIBRARY_SEARCH ChatMessageContentType,
  // which indicates the use of the library search tool.
  if ((referencedContent?.filter(content => content.type === ChatMessageContentType.LIBRARY_SEARCH) ?? []).length > 0) {
    toolsUsed.add(Tool.LIBRARY_SEARCH);
  }
  const inputReferencedContent = referencedContent?.filter(content => content.type !== ChatMessageContentType.OUTPUT);
  const outputReferencedContent = referencedContent?.filter(content => content.type === ChatMessageContentType.OUTPUT && getFileTypeFromMimeType(content.mimeType ?? '') === FileType.CSV);
  const renderMessageContent = () => {
    if (codeExecution) {
      return <CodeExecutionResult chatMessageCodeExecution={codeExecution} />;
    }
    if (isStreaming && smoothStreamingText) {
      return <Markdown chatMessageId={chatMessage.id} isStreaming>
          {smoothStreamingText}
        </Markdown>;
    }
    return <Markdown chatMessageId={chatMessage.id} isStreaming={isStreaming}>
        {displayedMessage}
      </Markdown>;
  };
  return <ResponseChatMessageContainer $isError={isError} $isHandledKindoError={isHandledKindoError} className="group/message">
      {(!!inputReferencedContent?.length || !!artifacts?.length) && !chatMessage.batch && <ContentAndToolReferencesContainer>
            {inputReferencedContent?.map(content => <ContentReference key={content.referenceId} canDownload={content.creatorId === userId} contentId={content.contentId} fileName={content.fileName} source={content.source} tooltip={content.sourceContent} />)}
            {artifacts?.map(artifact => <ContentReference key={artifact.contentId} canDownload={false} contentId={artifact.contentId} fileName={artifact.pageTitle} source={artifact.source} tooltip={artifact.sourceContent} url={artifact.url} />)}
          </ContentAndToolReferencesContainer>}

      <MessageTextContainer>
        <ModelNameAndMessageContainer>
          <Typography size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
            {codeExecution ? 'Code Execution Result' : model && LLM_DISPLAY_NAMES[model] || 'AI'}
            {toolsUsed.size > 0 && <Typography size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
                {` | Tools used: ${[...toolsUsed].map(tool => TOOL_DISPLAY_NAMES[tool]).join(', ')}`}
              </Typography>}
          </Typography>
          {renderMessageContent()}
        </ModelNameAndMessageContainer>
      </MessageTextContainer>
      {isError && !codeExecution && <ActionButtons>
          <HiddenButtons>
            {renderNewModelForErrorButton(message, model, isKindoError, onRegenerate)}
          </HiddenButtons>
        </ActionButtons>}
      {!isError && !codeExecution && <ActionButtons>
          <HiddenButtons>
            <CopyButton size={Size.SMALL} text={message} />
            {/*
              Hide regenerate if it is a workflow step for now, to avoid
              revealing the underlying prompt
             */}
            {onRegenerate && workflowStepNumber === undefined && <Button label="Re-generate" onClick={() => onRegenerate?.()} size={Size.SMALL} startIcon={Icon.RELOAD} type={ButtonType.OUTLINED} />}
            {isSlackConnected && <Dropdown highlightTriggerOnHover trigger={<Icons color={TextIconColor.PRIMARY} icon={Icon.SLACK} />}>
                {slackChannels.map(channel => <DropdownItem key={channel.id} onClick={() => handleShareToSlack(channel.id, displayedMessage)} title={`# ${channel.channelName}`} />)}
              </Dropdown>}
          </HiddenButtons>
        </ActionButtons>}
      {/** Output CSVs */}
      {outputReferencedContent?.map(content => <CsvViewerContainer key={content.contentId}>
          <CsvViewerHeaderContainer>
            <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
              CSV
            </Typography>
            <CsvContentReferenceContainer>
              <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.INTERACTIVE} size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
                Output file:
              </Typography>
              <ContentReference key={content.referenceId} canDownload={content.creatorId === userId} contentId={content.contentId} fileName={content.fileName} source={content.source} tooltip={content.sourceContent} />
            </CsvContentReferenceContainer>
          </CsvViewerHeaderContainer>
          <CsvViewer columnsWithTooltip={CSV_COLUMNS_WITH_TOOLTIP} contentReference={content} />
        </CsvViewerContainer>)}
    </ResponseChatMessageContainer>;
};
export default ResponseChatMessageComponent;