import _styled from "styled-components";
import { Button, ButtonType, Modal, ModalWidth, Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { nextTrpc } from '~/trpc';
interface TermsOfServiceModalProps {
  onComplete: () => void;
}
const TermsOfServiceModalContent = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyItems": "center",
  "gap": "1rem"
});
const TermsOfServiceLink = _styled.a({
  "textDecorationLine": "underline",
  "textDecorationColor": "#9ca3af"
});
const TermsOfServiceModal: React.FC<TermsOfServiceModalProps> = ({
  onComplete
}) => {
  const signTermsOfServiceMutation = nextTrpc.users.signTermsOfService.useMutation();
  const handleAcceptTerms = async () => {
    try {
      await signTermsOfServiceMutation.mutateAsync();
    } catch (error) {
      console.error('Failed to sign terms of service', error);
    } finally {
      onComplete();
    }
  };
  const {
    isLoading
  } = signTermsOfServiceMutation;
  return <Modal open width={ModalWidth['2XL']}>
      <TermsOfServiceModalContent>
        <Typography font={TypographyFont.HEADING} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
          Please Review Terms
        </Typography>
        <Typography font={TypographyFont.PARAGRAPH} size={TypographySize.SMALL}>
          By clicking "Agree", you confirm that you have read and understood our{' '}
          <TermsOfServiceLink href="http://kindo.ai/terms-of-use" target="_blank">
            Terms of Use
          </TermsOfServiceLink>
          ,{' '}
          <TermsOfServiceLink href="http://kindo.ai/terms-of-service" target="_blank">
            Terms of Service
          </TermsOfServiceLink>
          , and{' '}
          <TermsOfServiceLink href="http://kindo.ai/privacy-policy" target="_blank">
            Privacy Policy
          </TermsOfServiceLink>
          , and agree to comply with them.
        </Typography>
        <Button fullWidth label="Agree" loading={isLoading} onClick={handleAcceptTerms} type={ButtonType.SOLID} />
      </TermsOfServiceModalContent>
    </Modal>;
};
export default TermsOfServiceModal;