import _styled from "styled-components";
import { useRouter } from 'next/router';
import { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Icon, IconButton, IconButtonType, Size, Typography, TypographyFont, TypographySize } from '../../core';
import { AgentRunHistory, ChatHistory } from './components';
import { WorkstationHistorySidebarActiveTab, LocalStorageKey, WORKSTATION_ROUTE } from '~/constants';
import useLocalStorage from '~/hooks/useLocalStorage';
const WorkstationHistorySidebarContainer = _styled.div({
  "display": "flex",
  "height": "auto"
});
const WorkstationHistorySidebarInnerContainer = _styled.div<{
  $isOpen: boolean;
}>(({
  $isOpen
}) => [{
  "position": "relative",
  "display": "flex",
  "flexDirection": "column",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  "transitionDelay": "100ms"
}, $isOpen ? {
  "width": "340px"
} : {
  "width": "2.5rem"
}]);
const WorkstationHistorySidebarContentContainer = _styled.div<{
  $isOpen: boolean;
}>(({
  $isOpen
}) => [{
  "overflow": "hidden",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
  "opacity": "0",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  "transitionDelay": "100ms"
}, $isOpen ? {
  "boxSizing": "border-box",
  "height": "100%",
  "borderWidth": "0px",
  "borderRightWidth": "0.063rem",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))",
  "opacity": "1"
} : {
  "pointerEvents": "none"
}]);
const TopContainer = _styled.div({
  "display": "flex"
});
const TabButton = _styled.button<{
  $isActive: boolean;
  $isOpen: boolean;
}>(({
  $isActive,
  $isOpen
}) => [{
  "boxSizing": "border-box",
  "flex": "1 1 0%",
  "cursor": "pointer",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
  "opacity": "0",
  "transitionDuration": "300ms"
}, $isOpen ? {
  "opacity": "1",
  "transitionProperty": "opacity",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  "transitionDelay": "100ms"
} : {
  "pointerEvents": "none"
}, $isActive ? {
  "borderWidth": "0px",
  "borderRightWidth": "0.063rem"
} : {
  "borderWidth": "0.063rem",
  "borderLeftWidth": "0px",
  "borderTopWidth": "0px",
  "--tw-bg-opacity": "0.1",
  "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "100ms",
  "&:hover": {
    "--tw-bg-opacity": "0.1",
    "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))"
  }
}]);
const IconButtonContainer = _styled.div<{
  $isOpen: boolean;
  $isExpandButton?: boolean;
}>(({
  $isOpen,
  $isExpandButton
}) => [{
  "boxSizing": "border-box",
  "display": "flex",
  "height": "fit-content",
  "cursor": "pointer",
  "borderWidth": "0.063rem",
  "borderLeftWidth": "0px",
  "borderTopWidth": "0px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "0",
  "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "100ms",
  "&:hover": {
    "--tw-bg-opacity": "0.1",
    "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))"
  },
  ">button:enabled:hover": {
    "opacity": "1"
  },
  ">button": {
    "--tw-scale-x": "-1",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "transitionProperty": "transform",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "100ms"
  }
}, $isExpandButton && $isOpen && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(52 45 137 / var(--tw-bg-opacity))"
}, $isOpen && {
  ">button": {
    "--tw-scale-x": "1",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  }
}]);
const ScrollShadow = _styled.div<{
  $isOpen: boolean;
}>(({
  $isOpen
}) => [{
  "position": "absolute",
  "bottom": "0px",
  "left": "0px",
  "right": "1px",
  "height": "6rem",
  "backgroundImage": "linear-gradient(to top, var(--tw-gradient-stops))",
  "--tw-gradient-from": "#090909 var(--tw-gradient-from-position)",
  "--tw-gradient-to": "transparent var(--tw-gradient-to-position)",
  "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)"
}, !$isOpen && {
  "display": "none"
}]);
const WorkstationHistorySidebar: React.FC = () => {
  const [localStorageTab, setLocalStorageTab] = useLocalStorage<LocalStorageKey.HISTORY_MENU_ACTIVE_TAB>(LocalStorageKey.HISTORY_MENU_ACTIVE_TAB);
  const [mobileTab, setMobileTab] = useState<WorkstationHistorySidebarActiveTab>(WorkstationHistorySidebarActiveTab.CLOSED);

  // Use state on mobile, localStorage on desktop
  const activeTab = isMobileOnly ? mobileTab : localStorageTab;
  const setActiveTab = isMobileOnly ? setMobileTab : setLocalStorageTab;
  const router = useRouter();
  const isOpen = activeTab !== WorkstationHistorySidebarActiveTab.CLOSED;
  const handleHistoryItemClick = async (route: string) => {
    if (isMobileOnly) {
      setActiveTab(WorkstationHistorySidebarActiveTab.CLOSED);
      // Wait for animation to complete before navigation
      await new Promise(resolve => {
        setTimeout(resolve, 300);
      });
    }
    await router.push(route);
  };
  const renderTabButton = (tab: WorkstationHistorySidebarActiveTab, label: string) => <TabButton $isActive={activeTab === tab} $isOpen={isOpen} onClick={() => setActiveTab(tab)}>
      <Typography font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL}>
        {label}
      </Typography>
    </TabButton>;
  return <WorkstationHistorySidebarContainer>
      {!isMobileOnly && <WorkstationHistorySidebarInnerContainer $isOpen={isOpen}>
          <TopContainer>
            <IconButtonContainer $isExpandButton $isOpen={isOpen}>
              <IconButton icon={Icon.MENU_LEFT_ARROW} onClick={() => {
            if (isOpen) {
              setActiveTab(WorkstationHistorySidebarActiveTab.CLOSED);
            } else {
              setActiveTab(WorkstationHistorySidebarActiveTab.CHATS);
            }
          }} size={Size.LARGE} type={IconButtonType.SIMPLE} />
            </IconButtonContainer>
            {renderTabButton(WorkstationHistorySidebarActiveTab.CHATS, 'HISTORY')}
            {renderTabButton(WorkstationHistorySidebarActiveTab.AGENTS, 'AGENTS RUN')}
          </TopContainer>
          <WorkstationHistorySidebarContentContainer $isOpen={isOpen}>
            {activeTab === WorkstationHistorySidebarActiveTab.CHATS && <ChatHistory onItemClick={handleHistoryItemClick} />}
            {activeTab === WorkstationHistorySidebarActiveTab.AGENTS && <AgentRunHistory onItemClick={handleHistoryItemClick} />}
          </WorkstationHistorySidebarContentContainer>
          <ScrollShadow $isOpen={isOpen} />
        </WorkstationHistorySidebarInnerContainer>}
      <IconButtonContainer $isOpen>
        <IconButton icon={Icon.NEW_CHAT} onClick={async () => {
        setActiveTab(WorkstationHistorySidebarActiveTab.CLOSED);
        await new Promise(resolve => {
          setTimeout(resolve, 300);
        });
        await router.push(WORKSTATION_ROUTE);
      }} size={Size.LARGE} type={IconButtonType.SIMPLE} />
      </IconButtonContainer>
    </WorkstationHistorySidebarContainer>;
};
export default WorkstationHistorySidebar;