import { css as _css } from "styled-components";
import _styled from "styled-components";
export const TableContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "1.5rem"
});
export const TableRoot = _styled.table({
  "width": "100%",
  "tableLayout": "auto"
});
export const TableHead = _styled.thead({
  "borderStyle": "none"
});
export const TableHeaderRow = _styled.tr<{
  $showBorder: boolean;
}>(({
  $showBorder
}) => [$showBorder ? {
  "borderBottomWidth": "0.063rem",
  "borderColor": "rgba(230, 194, 255, 0.10)"
} : {
  "borderStyle": "none"
}]);
export const TableHeader = _styled.th<{
  $width: TwStyle | number | undefined;
}>(({
  $width
}) => [{
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "textAlign": "left"
}, typeof $width === 'number' ? _css({
  width: `${$width}px`
}) : $width]);
export const TableCell = _styled.td<{
  $height: TwStyle | number | undefined;
  $width: TwStyle | number | undefined;
}>(({
  $width,
  $height
}) => [{
  "height": "inherit",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
}, typeof $width === 'number' ? _css({
  width: `${$width}px`
}) : $width, typeof $height === 'number' ? _css({
  height: `${$height}px`
}) : $height]);
export const TableCellTypographyWrapper = _styled.div({
  "display": "flex"
});
export const StyledTableRow = _styled.tr<{
  $clickable: boolean;
  $disabled: boolean;
  $height: TwStyle;
  $hideBorder?: boolean;
}>(({
  $clickable,
  $disabled,
  $height,
  $hideBorder = false
}) => [$hideBorder ? {
  "borderStyle": "none"
} : {
  "borderBottomWidth": "0.063rem",
  "borderColor": "rgba(230, 194, 255, 0.10)"
}, $clickable && !$disabled && {
  "cursor": "pointer",
  "--tw-text-opacity": "1",
  "color": "rgb(84 254 209 / var(--tw-text-opacity))"
},
// Selectable
$disabled && {
  "cursor": "not-allowed"
}, $height]);
export const TableCheckboxTd = _styled.td({
  "textAlign": "center"
});
export const TableCheckboxOuterContainer = _styled.td<{
  $isSelected: boolean;
}>(({
  $isSelected
}) => [{
  "display": "inline-block",
  "height": "1.25rem",
  "width": "1.25rem",
  "alignItems": "center",
  "justifyContent": "center",
  "borderRadius": "0.25rem"
}, $isSelected ? {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(84 254 209 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(84 254 209 / var(--tw-bg-opacity))"
} : {
  "borderWidth": "1px",
  "borderColor": "rgb(255 255 255 / 0.5)",
  "backgroundColor": "transparent",
  "tr:hover &": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(84 254 209 / var(--tw-border-opacity))"
  }
}]);
export const TableCheckboxInnerContainer = _styled.div({
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "center"
});
export const TableNoRowsTd = _styled.td({
  "paddingTop": "2.25rem",
  "paddingBottom": "2.25rem",
  "paddingLeft": "1rem"
});