import _styled from "styled-components";
import { useState } from 'react';
import { Size, TextIconColor } from '../../constants';
import { Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '../../Typography';
import { TypographyTransition } from '../../Typography/Typography.consts';
import { TabNavigatorSize } from '../TabNavigator.consts';
const TAB_SIZE_TO_STYLES_MAP = {
  [Size.LARGE]: {
    weight: TypographyWeight.NORMAL,
    size: TypographySize.MEDIUM
  },
  [Size.SMALL]: {
    weight: TypographyWeight.SEMI_BOLD,
    size: TypographySize.SMALL
  }
} as const satisfies Record<TabNavigatorSize, Object>;
const TabOptionHighlightContainer = _styled.div<{
  $isSelected: boolean;
  $tabSize: TabNavigatorSize;
}>(({
  $isSelected,
  $tabSize
}) => [$isSelected && {
  "cursor": "default"
},
// Selected
$isSelected && $tabSize === Size.LARGE && {
  "borderBottomWidth": "2px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(32 0 200 / var(--tw-border-opacity))"
},
// Selected and large
$isSelected && $tabSize === Size.SMALL && {
  "borderBottomWidth": "0.063rem",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(255 255 255 / var(--tw-border-opacity))"
},
// Selected and small
!$isSelected && {
  "cursor": "pointer"
} // Not selected
]);
export interface TabOption<TabValue extends string> {
  label: string;
  value: TabValue;
}
interface TabOptionProp<TabValue extends string> {
  isSelected: boolean;
  onSelect: (value: TabValue) => void;
  tabOption: TabOption<TabValue>;
  size?: TabNavigatorSize;
}
const TabNavigatorOption = <TabValue extends string,>({
  tabOption,
  onSelect: handleClick,
  isSelected,
  size = Size.LARGE
}: TabOptionProp<TabValue>) => {
  const [isHovered, setIsHovered] = useState(false);
  const {
    size: textSize,
    weight: textWeight
  } = TAB_SIZE_TO_STYLES_MAP[size];
  const getTextIconColor = (): TextIconColor => {
    if (isSelected || isHovered) return TextIconColor.PRIMARY;
    return TextIconColor.SECONDARY;
  };
  return <TabOptionHighlightContainer $isSelected={isSelected} $tabSize={size} onClick={() => handleClick(tabOption.value)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Typography casing={TypographyCasing.UPPERCASE} color={getTextIconColor()} font={TypographyFont.INTERACTIVE} size={textSize} transition={TypographyTransition.COLORS} weight={textWeight}>
        {tabOption.label}
      </Typography>
    </TabOptionHighlightContainer>;
};
export default TabNavigatorOption;