export enum FeatureFlags {
  CODE_SANDBOX = 'CODE_SANDBOX',
  CONNECTABLE_INTEGRATIONS = 'CONNECTABLE_INTEGRATIONS',
  FEATURE_FLAGGED_LLMS = 'FEATURE_FLAGGED_LLMS',
  HIDE_PUBLIC_WORKFLOWS = 'HIDE_PUBLIC_WORKFLOWS',
  KNOWLEDGE_STORE = 'KNOWLEDGE_STORE',
  SLACK_INTEGRATION = 'SLACK_INTEGRATION',
  TRIGGER_AUTOMATED_WORKFLOW_ASYNC = 'TRIGGER_AUTOMATED_WORKFLOW_ASYNC',
  UNLIMITED_CREDIT_USAGE = 'UNLIMITED_CREDIT_USAGE',
  YOUTUBE_DOWNLOAD = 'YOUTUBE_DOWNLOAD'
}
