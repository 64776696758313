import _styled from "styled-components";
import { MANAGE_SUBSCRIPTION_FULL_ROUTE } from '@kindo/universal';
import { useRouter } from 'next/router';
import { Button, ButtonType, Modal, Typography, TypographyAlignment, TypographySize, TypographyWeight } from '~/components/core';
import { LIBRARY_ROUTE } from '~/constants';
import { useAppSelector } from '~/hooks';
import { PaywallModalPayload, PaywallModalType } from '~/redux/reducers/modalSlice';
const PaywallModalContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "1rem"
});
const HeaderAndSubtextContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "center",
  "gap": "0.5rem"
});
const ButtonsContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "flex-end",
  "gap": "0.5rem"
});
const PAYWALL_MODAL_HEADERS: Record<PaywallModalType, string> = {
  [PaywallModalType.WORKFLOWS]: 'Max Agent limit reached'
};
const PAYWALL_MODAL_SUBTEXTS: Record<PaywallModalType, string> = {
  [PaywallModalType.WORKFLOWS]: 'Your organization currently has the maximum number of created agents allowed for your plan. To build a new agent, please contact sales to upgrade your plan, or delete existing agents.'
};
const getPaywallModalLimitInformation = (payload: PaywallModalPayload, maxWorkflows: number | null | undefined): string | undefined => {
  switch (payload.paywallModalType) {
    case PaywallModalType.WORKFLOWS:
      return payload.existingWorkflowCount !== undefined ? `Current agents: ${payload.existingWorkflowCount} / ${maxWorkflows}` : `Current limit: ${maxWorkflows}`;
    default:
      return undefined;
  }
};
interface PaywallModalProps {
  onClose: () => void;
  payload: PaywallModalPayload;
}
const PaywallModal: React.FC<PaywallModalProps> = ({
  onClose,
  payload
}) => {
  const router = useRouter();
  // Redux
  const {
    org
  } = useAppSelector(state => state.user);
  const maxWorkflows = org?.paymentTierConfig.maxWorkflows;
  const paywallModalLimitInformation = getPaywallModalLimitInformation(payload, maxWorkflows);
  const redirectToManageSubscription = async (route: string) => {
    onClose();
    await router.replace(route);
  };
  return <Modal onClose={onClose} open>
      <PaywallModalContainer>
        <HeaderAndSubtextContainer>
          <Typography alignment={TypographyAlignment.CENTER} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
            {PAYWALL_MODAL_HEADERS[payload.paywallModalType]}
          </Typography>
          <Typography alignment={TypographyAlignment.CENTER}>
            {PAYWALL_MODAL_SUBTEXTS[payload.paywallModalType]}
          </Typography>
          {!!paywallModalLimitInformation && <Typography alignment={TypographyAlignment.CENTER} weight={TypographyWeight.SEMI_BOLD}>
              {paywallModalLimitInformation}
            </Typography>}
        </HeaderAndSubtextContainer>
        <ButtonsContainer>
          <Button label="Return to Library" onClick={() => redirectToManageSubscription(LIBRARY_ROUTE)} type={ButtonType.OUTLINED} />
          <Button label="Manage Subscription" onClick={() => redirectToManageSubscription(MANAGE_SUBSCRIPTION_FULL_ROUTE)} type={ButtonType.SOLID} />
        </ButtonsContainer>
      </PaywallModalContainer>
    </Modal>;
};
export default PaywallModal;