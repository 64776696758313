import _styled from "styled-components";
import { Icon, IconButton, IconButtonType, Size, TextIconColor } from '~/components/core';
import { withLink } from '~/components/core/hocs';
const NavButtonContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center"
});
interface NavButtonProps {
  icon: Icon;
  route: string;
  'data-id'?: string;
  hideActive?: boolean;
  isCurrentRoute?: boolean;
}
const NavButton: React.FC<NavButtonProps> = ({
  isCurrentRoute,
  icon,
  route,
  'data-id': dataId
}) => withLink(<NavButtonContainer>
      <IconButton color={isCurrentRoute ? TextIconColor.HIGHLIGHT : TextIconColor.PRIMARY} data-id={dataId ?? ''} href={route} icon={icon} size={Size.LARGE} type={IconButtonType.NONE} />
    </NavButtonContainer>, {
  href: route
});
export default NavButton;