import { Size } from '../constants';
export type IconButtonSize = Extract<Size, Size.SMALL | Size.MEDIUM | Size.LARGE>;
export enum IconButtonType {
  NONE = 'NONE',
  OUTLINED = 'OUTLINED',
  OUTLINED_COLOR = 'OUTLINED_COLOR',
  SIMPLE = 'SIMPLE',
  SOLID = 'SOLID',
  SOLID_COLOR = 'SOLID_COLOR',
  SOLID_GRADIENT = 'SOLID_GRADIENT',
}