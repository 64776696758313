import _styled from "styled-components";
import { Llm } from '@kindo/universal';
import { useState } from 'react';
import { ContentReference } from '../../../shared/ContentReference';
import { AddFileModal, FileResource } from '../../AddFileModal';
import { Button, ButtonType, TextIconColor, Icon, Icons, Size, TextField, TextFieldType, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { PromptTemplateWorkflowStepInput } from '~/types';
const InputContainer = _styled.div({
  "display": "flex",
  "flexGrow": "1",
  "flexDirection": "column",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-y-reverse": "0",
    "marginTop": "calc(1rem * calc(1 - var(--tw-space-y-reverse)))",
    "marginBottom": "calc(1rem * var(--tw-space-y-reverse))"
  }
});
const InputInfoContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "space-between"
});
const InputNumberAndLabel = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "gap": "0.75rem"
});
const InputButtonsAndSelectedFiles = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "flex-start",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-x-reverse": "0",
    "marginRight": "calc(1rem * var(--tw-space-x-reverse))",
    "marginLeft": "calc(1rem * calc(1 - var(--tw-space-x-reverse)))"
  },
  "whiteSpace": "nowrap"
});
const IsReadyIconContainer = _styled.div({
  "display": "flex",
  "height": "36px",
  "alignItems": "center",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
const TextFieldContainer = _styled.div({
  "justifyContent": "flex-end",
  "paddingRight": "4rem"
});
export enum UserInputValueType {
  CONTENT = 'CONTENT',
  TEXT = 'TEXT',
}
interface BaseUserChatMessageInputValue {
  inputId: string;
  type: UserInputValueType;
}
interface UserChatMessageTextInputValue extends BaseUserChatMessageInputValue {
  type: UserInputValueType.TEXT;
  value: string;
}
interface UserChatMessageContentInputValue extends BaseUserChatMessageInputValue {
  content: FileResource;
  type: UserInputValueType.CONTENT;
}
export type UserChatMessageInputValue = UserChatMessageTextInputValue | UserChatMessageContentInputValue;
interface UserChatMessageInputProps {
  input: PromptTemplateWorkflowStepInput;
  inputValue: UserChatMessageInputValue | undefined;
  model: Llm | null;
  setInputValue: (value: UserChatMessageInputValue | undefined, inputId: string) => void;
}

/**
 * Component that accepts inputs needed to send a ready chat message.
 */
const UserChatMessageInput: React.FC<UserChatMessageInputProps> = ({
  input,
  inputValue,
  model,
  setInputValue
}) => {
  // Note: Use switchActiveInputType instead of default set for cleanup
  const [activeInputType, setActiveInputTypeWithoutCleanup] = useState<UserInputValueType | undefined>();
  const isTextInputActive = activeInputType === UserInputValueType.TEXT;
  const [showAddFileModal, setShowAddFileModal] = useState(false);
  const textInputValue = inputValue?.type === UserInputValueType.TEXT ? inputValue.value : '';
  const selectedContent = inputValue?.type === UserInputValueType.CONTENT ? inputValue.content : null;
  const setValue = (value: UserChatMessageInputValue | undefined) => setInputValue(value, input.id);
  const setTextInputValue = (value: string) => setValue({
    inputId: input.id,
    type: UserInputValueType.TEXT,
    value
  });
  const setContentInputValue = (value: FileResource) => setValue({
    inputId: input.id,
    type: UserInputValueType.CONTENT,
    content: value
  });

  // Switch input type, and reset values (saved and local state)
  const switchActiveInputType = (newActiveInputType: UserInputValueType | undefined) => {
    if (newActiveInputType === activeInputType) return;
    setActiveInputTypeWithoutCleanup(newActiveInputType);
    setValue(undefined);
  };
  const handleSelectContent = (file: FileResource) => {
    switchActiveInputType(UserInputValueType.CONTENT);
    setContentInputValue(file);
  };
  const trimTextInput = () => {
    const trimmedInputValue = textInputValue.trim();
    if (!trimmedInputValue) {
      switchActiveInputType(undefined);
    }
    setTextInputValue(trimmedInputValue);
  };
  const handleTextInputButtonClick = () => {
    // If input type is not currently text, switch to text
    if (!isTextInputActive) {
      switchActiveInputType(UserInputValueType.TEXT);
      return;
    }

    // If the input type is text, reset the input type
    switchActiveInputType(undefined);
  };
  const isReady = activeInputType === UserInputValueType.TEXT && !!textInputValue || activeInputType === UserInputValueType.CONTENT && !!selectedContent;
  return <>
      <InputContainer>
        <InputInfoContainer>
          <InputNumberAndLabel>
            <Typography color={TextIconColor.PRIMARY} weight={isReady ? TypographyWeight.NORMAL : TypographyWeight.MEDIUM}>
              {input.displayName}
            </Typography>
          </InputNumberAndLabel>
          <InputButtonsAndSelectedFiles>
            {/* Buttons for selecting input type */}
            {!selectedContent && <>
                {/* Text input */}
                <Button data-id="variable_text" label="Text" onClick={handleTextInputButtonClick} startIcon={isTextInputActive ? Icon.CLOSE : Icon.PLUS} type={ButtonType.OUTLINED} />
                {/* File input */}
                {!isTextInputActive && <Button data-id="variable_file" label="File" onClick={() => {
              setShowAddFileModal(true);
            }} startIcon={Icon.PLUS} type={ButtonType.OUTLINED} />}
              </>}
            {/* Display selected file  */}
            {selectedContent && <ContentReference contentId={selectedContent.id} fileName={selectedContent.name} onClick={() => setValue(undefined)} showRemoveIcon source={selectedContent.source} />}
            <IsReadyIconContainer>
              <Icons color={isReady ? TextIconColor.HIGHLIGHT : TextIconColor.SECONDARY} icon={Icon.CHECK} size={Size.LARGE} />
            </IsReadyIconContainer>
          </InputButtonsAndSelectedFiles>
        </InputInfoContainer>
        {activeInputType === UserInputValueType.TEXT && <TextFieldContainer>
            {/* TODO: Add auto-grow once we add functionality to the component */}
            <TextField autoFocus onBlur={trimTextInput} onChange={value => {
          setTextInputValue(value);
        }} onEnter={trimTextInput} size={TypographySize.SMALL} type={TextFieldType.HIGHLIGHT} value={textInputValue} />
          </TextFieldContainer>}
      </InputContainer>
      {showAddFileModal && model && <AddFileModal activeModel={model} initialSelectedFiles={selectedContent ? [selectedContent] : []} onClose={() => setShowAddFileModal(false)} onSelect={files => {
      const file = files[0];
      if (files.length !== 1 || !file) {
        console.error(`Expected exactly one file, received ${files}`);
        return;
      }
      handleSelectContent(file);
    }} singleSelection />}
    </>;
};
export default UserChatMessageInput;