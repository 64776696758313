import { Integration } from '@kindo/universal';

import { Icon } from '~/components/core';
import { ContentSource } from '~/types';

export const getContentIntegrationIcon = (integration: ContentSource): Icon => {
  switch (integration) {
    // TODO [ENG-4669]: Add icons for other supported integrations that ingest content
    case Integration.GOOGLE_DRIVE:
      return Icon.GOOGLE_DRIVE;
    case 'Web':
      return Icon.SEARCH; // TODO: Use a dedicated icon for different artifact results
    case 'Library':
    default:
      return Icon.FILE;
  }
};
