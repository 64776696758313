export enum TextIconColor {
  BLACK = 'BLACK',
  DESTRUCTIVE = 'DESTRUCTIVE',
  DISABLED = 'DISABLED',
  HIGHLIGHT = 'HIGHLIGHT',
  PRIMARY = 'PRIMARY',
  PRIMARY_HIGHLIGHT = 'PRIMARY_HIGHLIGHT',
  SECONDARY = 'SECONDARY',
  SECONDARY_HIGHLIGHT = 'SECONDARY_HIGHLIGHT',
  SLATE = 'SLATE',
}
export const TEXT_COLORS: Record<TextIconColor, TwStyle> = {
  [TextIconColor.BLACK]: {
    "--tw-text-opacity": "1",
    "color": "rgb(22 25 37 / var(--tw-text-opacity))"
  },
  [TextIconColor.DISABLED]: {
    "color": "rgb(255 255 255 / 0.5)"
  },
  [TextIconColor.PRIMARY]: {
    "--tw-text-opacity": "1",
    "color": "rgb(255 255 255 / var(--tw-text-opacity))"
  },
  [TextIconColor.SECONDARY]: {
    "--tw-text-opacity": "1",
    "color": "rgb(150 148 181 / var(--tw-text-opacity))"
  },
  [TextIconColor.HIGHLIGHT]: {
    "--tw-text-opacity": "1",
    "color": "rgb(84 254 209 / var(--tw-text-opacity))"
  },
  [TextIconColor.PRIMARY_HIGHLIGHT]: {
    "--tw-text-opacity": "1",
    "color": "rgb(141 118 255 / var(--tw-text-opacity))"
  },
  [TextIconColor.SECONDARY_HIGHLIGHT]: {
    "--tw-text-opacity": "1",
    "color": "rgb(120 116 254 / var(--tw-text-opacity))"
  },
  // Used for buttons in Markdown components
  [TextIconColor.DESTRUCTIVE]: {
    "--tw-text-opacity": "1",
    "color": "rgb(255 51 108 / var(--tw-text-opacity))"
  },
  [TextIconColor.SLATE]: {
    "color": "#8CAEBA33"
  }
};