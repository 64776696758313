import _styled from "styled-components";
import { TextField, TextFieldType, TextIconColor, Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
const QuestionContainer = _styled.div({
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
});
type TextQuestionProps = {
  onChange: (value: string) => void;
  placeholder: string;
  prompt: string;
  value: string;
};
const TextQuestion = ({
  value,
  onChange,
  placeholder,
  prompt
}: TextQuestionProps) => <>
    <QuestionContainer>
      <Typography color={TextIconColor.PRIMARY} font={TypographyFont.PARAGRAPH} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD}>
        {prompt}
      </Typography>
    </QuestionContainer>
    <TextField autoFocus onChange={newValue => onChange(newValue)} placeholder={placeholder} type={TextFieldType.HIGHLIGHT} value={value} />
  </>;
export default TextQuestion;