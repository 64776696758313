import _styled from "styled-components";
import { Button, ButtonType, TextIconColor, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { MARKETING_WEBSITE_CONTACT_URL } from '~/constants';
const PaywallContainer = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between"
});
const PaywallTextContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem"
});
interface PaywallTextProps {
  subtitle?: string | null;
  title?: string;
}
const PaywallText: React.FC<PaywallTextProps> = ({
  title,
  subtitle
}) => <PaywallContainer>
    <PaywallTextContainer>
      <Typography color={TextIconColor.DESTRUCTIVE} size={TypographySize.LARGE} weight={TypographyWeight.MEDIUM}>
        {title || 'This feature is limited for Free and Pro users.'}
      </Typography>
      {subtitle !== null && <Typography color={TextIconColor.DESTRUCTIVE}>
          {subtitle || 'To inquire about upgrading, please contact sales.'}
        </Typography>}
    </PaywallTextContainer>
    <Button href={MARKETING_WEBSITE_CONTACT_URL} label="Contact Sales" type={ButtonType.SOLID} />
  </PaywallContainer>;
export default PaywallText;