import { RequireOnlyOne } from '@kindo/universal';
import React from 'react';
import { TextIconColor, Size } from '../constants';
import { withLink } from '../hocs';
import { Icon } from '../Icon/Icon.consts';
import Icons from '../Icon/Icons';
import { LoadingSpinner, LoadingSpinnerSize } from '../LoadingSpinner';
import { ToolTip, ToolTipColor } from '../ToolTip';
import { TooltipPlacement } from '../ToolTip/ToolTip';
import { IconButtonSize, IconButtonType } from './IconButton.consts';
import { StyledIconButton } from './IconButton.styles';
interface BaseIconButtonProps {
  href: string;
  icon: Icon;
  onClick: () => void;
  type: IconButtonType;
  color?: TextIconColor;
  'data-id'?: string;
  disabled?: boolean;
  invert?: boolean;
  loading?: boolean;
  size?: IconButtonSize;
  title?: string;
  tooltip?: string;
}

// Ensures that either onClick or href is provided
type IconButtonProps = RequireOnlyOne<BaseIconButtonProps, 'onClick' | 'href'>;
const IconButton: React.FC<IconButtonProps> = ({
  icon,
  type,
  onClick,
  href,
  color = TextIconColor.PRIMARY,
  'data-id': dataId,
  disabled = false,
  invert = false,
  loading = false,
  size = Size.MEDIUM,
  title,
  tooltip
}) => {
  const getIconColor = () => {
    if (disabled) return TextIconColor.DISABLED;
    return color;
  };
  const iconColor = getIconColor();
  const disableOnClick = disabled || loading;
  const handleOnClick = (e: React.MouseEvent) => {
    if (disableOnClick) return;

    // Do NOT stop propagation if it's a link,
    // it will break next routing from working properly
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };
  return withLink(<ToolTip color={ToolTipColor.BLUE} content={tooltip ?? ''} offset={[0, 8]} placement={TooltipPlacement.TOP_START}>
      <StyledIconButton $size={size} $type={type} data-id={dataId} disabled={disabled} onClick={handleOnClick} title={title}>
        {!loading && <Icons color={iconColor} icon={icon} invert={invert} size={size} />}
        {loading && <LoadingSpinner size={LoadingSpinnerSize.SMALL} />}
      </StyledIconButton>
    </ToolTip>, {
    href
  });
};
export default IconButton;