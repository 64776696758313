import _styled from "styled-components";
import { ContentProcessingMode, CSVProcessingMode } from '@kindo/api/src/services/chatMessage/utils/chatMessage.types';
import { ChatMessageRole, ChatMessageState, ContentMode, DEFAULT_WORKFLOW_NAME, ExternalLlm, MANAGE_SUBSCRIPTION_FULL_ROUTE, OrgPaymentTier, Tool, ChatErrorCode, getKindoErrorMessage, SupportedLlm } from '@kindo/universal';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { CHAT_ID_QUERY_PARAM, LocalStorageKey, UNTITLED_CHAT_NAME, WORKFLOW_ID_QUERY_PARAM } from '../../constants';
import { nextTrpc } from '../../trpc';
import { ChatMessages, DisplayedChatMessage } from '../Chat';
import { Banner } from '../core/Banner';
import { BannerType } from '../core/Banner/Banner';
import { Layout } from '../Layout';
import { PromptBar } from '../PromptBar';
import { DlpFiltersConfig } from '../SecuritySettings/OrgSecurityControls/DlpFilters/DlpFiltersConfig/DlpFilters.types';
import { WorkstationHeader } from './WorkstationHeader';
import { WorkstationHistorySidebar } from './WorkstationHistorySidebar';
import { FileResource } from '~/components/Chat/AddFileModal';
import { ToastType, useAppSelector, useScrollToEndOnChat, useToast, useURLParams } from '~/hooks';
import useGetCredits from '~/hooks/useGetOrgCredits';
import useLocalStorage from '~/hooks/useLocalStorage';
import { HeapEvent, HeapEventPayload, getAttachedContentTypesString, trackHeapEvent } from '~/utils';
const DEFAULT_CHAT_TITLE = 'New Chat';
const WorkstationScreenContainer = _styled.div({
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "flexDirection": "column",
  "@media (min-width: 768px)": {
    "flexDirection": "row"
  }
});
const WorkstationScreenMainSection = _styled.div({
  "marginLeft": "auto",
  "marginRight": "auto",
  "display": "flex",
  "height": "calc(100vh - 4rem)",
  "maxWidth": "1200px",
  "overflowY": "hidden",
  "@media (min-width: 768px)": {
    "height": "100%",
    "width": "66.666667%",
    "padding": "0.75rem"
  }
});
const WorkstationScreenContent = _styled.div({
  "position": "relative",
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "flexDirection": "column",
  "justifyContent": "space-between",
  "@media (min-width: 640px)": {
    "paddingLeft": "1rem",
    "paddingRight": "1rem"
  }
});
const PromptBarContainer = _styled.div({
  "position": "sticky",
  "bottom": "0px",
  "height": "fit-content",
  "paddingBottom": "1.25rem"
});

// Dynamically import LogoAnimation to reduce initial bundle size
// since it's only shown on empty chat states
const LogoAnimation = dynamic(() => import('../core/LogoAnimation').then(mod => mod.LogoAnimation), {
  ssr: false
  // TODO: Replace fallback with svg of logo
  // loading: () => <KindoLogo />
});

/**
 * Workstation can currently be used for:
 *   - A home screen landing page
 *   - An existing chat from chat history
 *   - Running a workflow, then chatting after with the workflow context
 *
 */

const WorkstationScreen: React.FC = () => {
  // State
  const [chatMessages, setChatMessages] = useState<DisplayedChatMessage[]>([]);
  const [shouldPollChatData, setShouldPollChatData] = useState<boolean>(false);

  // Redux
  const {
    contentMode
  } = useAppSelector(state => state.chat);
  const {
    org
  } = useAppSelector(state => state.user);
  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  const {
    orgCredits
  } = useGetCredits();
  // TODO: Default if LocalStorage Model is not allowed
  const [chatModel] = useLocalStorage<LocalStorageKey.CHAT_LLM>(LocalStorageKey.CHAT_LLM);
  const [enabledTools] = useLocalStorage<LocalStorageKey.ENABLED_TOOLS>(LocalStorageKey.ENABLED_TOOLS);
  const router = useRouter();
  const updateUrlWithChatId = (chatId: string) => {
    const query = {
      [WORKFLOW_ID_QUERY_PARAM]: router.query[WORKFLOW_ID_QUERY_PARAM],
      [CHAT_ID_QUERY_PARAM]: chatId
    };
    void router.replace({
      pathname: router.pathname,
      query
    }, undefined, {
      shallow: true
    });
  };

  // URL Params
  const params = useURLParams<{
    [CHAT_ID_QUERY_PARAM]?: string;
    [WORKFLOW_ID_QUERY_PARAM]?: string;
  }>();
  const {
    [CHAT_ID_QUERY_PARAM]: urlChatId,
    [WORKFLOW_ID_QUERY_PARAM]: urlWorkflowId
  } = params;
  const {
    handleScroll,
    scrollDivRef
  } = useScrollToEndOnChat(chatMessages);
  const nextTrpcContext = nextTrpc.useContext();

  // Constants
  const enabledToolsArray = JSON.parse(enabledTools);

  // Queries

  // If chat id is in url param, fetch chat
  const {
    data: chatData,
    refetch: refetchChat
  } = nextTrpc.chats.get.useQuery({
    chatId: urlChatId ?? ''
  }, {
    enabled: !!urlChatId,
    refetchInterval: shouldPollChatData ? 3000 : false
  });
  const chat = chatData?.item;
  const {
    data: workflowData
  } = nextTrpc.workflows.get.useQuery({
    workflowId: urlWorkflowId ?? ''
  }, {
    enabled: !!urlWorkflowId
  });
  const {
    data: dlpFilters
  } = nextTrpc.dlpFiltersConfig.getOrgDlpFilters.useQuery({
    model: chatModel as ExternalLlm
  });
  const workflow = workflowData?.item;

  // Mutations
  const sendChatMessageMutation = nextTrpc.chatMessage.sendChatMessage.useMutation();
  const sendChatMessageLoading = sendChatMessageMutation.isLoading;
  const stopGenerationOfPendingMessagesMutation = nextTrpc.chatMessage.stopGenerationOfPendingMessages.useMutation();
  const createChatWithInitialMessageMutation = nextTrpc.chats.createChatWithInitialMessage.useMutation({
    onSuccess: ({
      item: newChat
    }) => {
      updateUrlWithChatId(newChat.id);
    },
    onError: error => {
      console.error('Failed to create chat', error);
      enqueueToast({
        type: ToastType.ERROR,
        message: error.data?.kindoErrorMessage || 'Failed to create chat',
        autoClose: false
      });
    }
  });
  const createChatFromWorkflowMutation = nextTrpc.chats.createChatFromWorkflow.useMutation({
    onSuccess: ({
      item: newChat
    }) => {
      updateUrlWithChatId(newChat.id);
    },
    onError: error => {
      console.error('Failed to create chat for workflow', error);
      enqueueToast({
        type: ToastType.ERROR,
        message: error.data?.kindoErrorMessage || 'Failed to create chat for workflow',
        autoClose: false
      });
    }
  });
  const getContentInput = (contentIds: string[]) => ({
    contentIds,
    contentProcessingMode: contentMode === ContentMode.PARALLEL ? ContentProcessingMode.PARALLEL : ContentProcessingMode.COMBINED,
    csvProcessingMode: contentMode === ContentMode.CSV_ROW_BY_ROW ? CSVProcessingMode.ROW_BY_ROW : CSVProcessingMode.WHOLE
  });
  const getFilteredEnabledTools = () => enabledToolsArray.includes(Tool.URL_SCRAPE) && dlpFilters?.dlpFiltersConfig.url === 'REDACT' ? enabledToolsArray.filter((tool: Tool) => tool !== Tool.URL_SCRAPE) : enabledToolsArray;

  // Returns true if successful, false if not
  const sendChatMessage = async ({
    chatID,
    message,
    contentIds,
    model
  }: {
    chatID: string;
    contentIds: string[];
    message: string;
    model?: SupportedLlm;
  }) => {
    if (!message) {
      return;
    }
    const filteredContentIds = contentIds.filter(id => !!id);
    try {
      await sendChatMessageMutation.mutateAsync({
        chatId: chatID,
        message,
        model: model ?? chatModel,
        contentInput: getContentInput(filteredContentIds),
        enabledTools: getFilteredEnabledTools()
      });
    } catch (e: any) {
      console.error(e);
      enqueueToast({
        type: ToastType.ERROR,
        message: e.data?.kindoErrorMessage || getKindoErrorMessage(ChatErrorCode.CHAT_UNEXPECTED_ERROR),
        autoClose: false
      });
    } finally {
      void nextTrpcContext.chats.getChatMessages.invalidate({
        chatId: chatID
      });
    }
  };
  const createChatFromWorkflowId = (workflowId: string) => createChatFromWorkflowMutation.mutate({
    workflowId
  });
  const createChatFromInitialMessage = ({
    initialMessage,
    selectedFiles
  }: {
    initialMessage: string;
    selectedFiles: FileResource[];
  }) => {
    setChatMessages([{
      id: 'temp',
      message: initialMessage,
      model: chatModel,
      role: ChatMessageRole.USER,
      state: ChatMessageState.OK,
      displayName: undefined,
      transformedMessage: undefined
    }]);
    const contentIds = selectedFiles.map(file => file.id);
    createChatWithInitialMessageMutation.mutate({
      initialMessage: {
        message: initialMessage,
        contentInput: getContentInput(contentIds),
        enabledTools: getFilteredEnabledTools()
      },
      model: chatModel
    });
  };

  // If there's no existing chatId and there's a workflow id,
  // create a new chat from it
  useEffect(() => {
    if (!urlChatId && urlWorkflowId) {
      createChatFromWorkflowId(urlWorkflowId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlChatId, urlWorkflowId]);
  const getWorkstationTitle = () => {
    const fallbackTitle = urlWorkflowId ? DEFAULT_WORKFLOW_NAME : DEFAULT_CHAT_TITLE;
    const title = chat?.title || workflow?.displayName || fallbackTitle;
    return title;
  };
  const title = getWorkstationTitle();

  /**
   * If title is default, then poll chat data to retrieve generated title.
   * When title changes and is no longer default, stop polling.
   *
   * TODO: We can probably improve and refactor this so it doesn't need a useEffect.
   */
  useEffect(() => {
    if (title === DEFAULT_CHAT_TITLE) {
      setShouldPollChatData(true);
    } else {
      setShouldPollChatData(false);
    }
  }, [title]);
  const handleSendChatMessage = async ({
    message,
    selectedFiles,
    modelToUse
  }: {
    message: string;
    selectedFiles: FileResource[];
    modelToUse?: SupportedLlm;
  }) => {
    const chatId = chat?.id;
    const contentIds = selectedFiles.map(file => file.id);

    // Used for heap tracking
    const attachedContentTypes = getAttachedContentTypesString(selectedFiles);
    if (!chatId) {
      createChatFromInitialMessage({
        initialMessage: message,
        selectedFiles
      });
      return;
    }
    setChatMessages(prev => [...prev, {
      id: 'temp',
      message,
      model: chatModel,
      role: ChatMessageRole.USER,
      state: ChatMessageState.OK,
      displayName: undefined,
      transformedMessage: undefined,
      enabledTools: getFilteredEnabledTools()
    }]);
    await sendChatMessage({
      chatID: chatId,
      message,
      contentIds,
      model: modelToUse ?? chatModel
    });
    const heapEventPayload: HeapEventPayload = urlWorkflowId ? {
      attachedContentTypes,
      event: HeapEvent.WORKFLOW_PROMPT,
      promptText: message,
      workflowId: urlWorkflowId
    } : {
      attachedContentTypes,
      event: HeapEvent.CHAT_PROMPT,
      promptText: message
    };
    trackHeapEvent(heapEventPayload);
  };
  const handleStopGeneration = async () => {
    const pendingMessages = chatMessages.filter(message => message.state === ChatMessageState.PENDING).map(pendingMessage => ({
      messageId: pendingMessage.id,
      currentMessage: pendingMessage.message
    }));
    const pendingMessagesIds = pendingMessages.map(pendingMessage => pendingMessage.messageId);
    if (pendingMessages.length > 0 && chat) {
      await stopGenerationOfPendingMessagesMutation.mutateAsync({
        messages: pendingMessages
      });
      setChatMessages(prev => prev.map(message => {
        if (pendingMessagesIds.includes(message.id)) {
          return {
            ...message,
            state: ChatMessageState.STOPPED
          };
        }
        return message;
      }));
    }
  };
  const waitingForChatAnswer =
  // Check if urlChatId exists, to avoid loading state persisting
  // when creating a new chat while one is still loading
  !!urlChatId && (sendChatMessageLoading || chatMessages.some(message => message.state === ChatMessageState.PENDING));

  // Disable prompt bar if
  // - message is pending
  // - workflow is in-progress (has a queued or ready message)
  // - isGeminiMultiModalChat
  const isInProgressWorkflow = chatMessages.some(message => message.state === ChatMessageState.QUEUED || message.state === ChatMessageState.READY);
  const disablePromptBar = waitingForChatAnswer || isInProgressWorkflow;
  const getPromptBarPlaceholder = () => {
    if (isInProgressWorkflow) {
      return 'Run all agent steps before asking a follow-up question';
    }
    if (chatMessages.length > 0) {
      return 'Ask a follow up question';
    }
    return 'Type to chat, find, or create anything';
  };
  const isEnterprise = org?.paymentTier === OrgPaymentTier.ENTERPRISE;
  return <Layout>
      <WorkstationScreenContainer>
        {orgCredits !== undefined && orgCredits < 500 && (!isEnterprise ? <Banner redirectButton={{
        label: 'Upgrade Plan',
        href: MANAGE_SUBSCRIPTION_FULL_ROUTE
      }} text="Warning: Low credits. You'll receive additional credits each day. You can check your credit balance by clicking the profile button in the sidebar." type={BannerType.WARNING} /> : <Banner text="Warning: Low credits. You'll receive additional credits each day. You can check your credit balance by clicking the profile button in the sidebar." type={BannerType.WARNING} />)}
        <WorkstationHistorySidebar />
        <WorkstationScreenMainSection className="group">
          <WorkstationScreenContent ref={scrollDivRef} onScroll={handleScroll}>
            {chat && <WorkstationHeader chatId={urlChatId} refetchChat={refetchChat} title={title} workflow={workflow} />}
            {!chat && <LogoAnimation />}
            {chat && <ChatMessages chatId={chat.id} chatMessages={chatMessages} chatTitle={chat.title ?? UNTITLED_CHAT_NAME} isUsingTools={!!enabledToolsArray.length} sendMessage={handleSendChatMessage} setChatMessages={setChatMessages} waitingForChatAnswer={waitingForChatAnswer} />}
            <PromptBarContainer>
              <PromptBar autoFocus disabledSubmit={disablePromptBar} disableTextInput={isInProgressWorkflow} dlpFilters={dlpFilters?.dlpFiltersConfig as DlpFiltersConfig} loading={waitingForChatAnswer} maxHeight={110} onStopGeneration={handleStopGeneration} onSubmit={handleSendChatMessage} placeholder={getPromptBarPlaceholder()} workflow={workflow} />
            </PromptBarContainer>
          </WorkstationScreenContent>
        </WorkstationScreenMainSection>
      </WorkstationScreenContainer>
    </Layout>;
};
export default WorkstationScreen;