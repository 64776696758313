import _styled from "styled-components";
import { TextIconColor, Icon, Icons, Typography, TypographySize, TypographyFont } from '../core';
const StyledDropdownItem = _styled.div<{
  $destructive: boolean;
  $disabled: boolean;
}>(({
  $disabled,
  $destructive
}) => [{
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "&:hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(53 17 245 / var(--tw-bg-opacity))"
  }
}, $disabled ? {
  "cursor": "not-allowed",
  "opacity": "0.5"
} : [{
  "cursor": "pointer"
}, $destructive && {
  "&:hover>*": {
    "--tw-text-opacity": "1",
    "color": "rgb(255 255 255 / var(--tw-text-opacity))"
  }
}]]);
export type DropdownItemProps = {
  onClick: () => void;
  title: string;
  destructive?: boolean | undefined;
  disabled?: boolean;
  icon?: Icon | undefined;
};
export const DropdownItem: React.FC<DropdownItemProps> = ({
  icon,
  title,
  onClick,
  destructive = false,
  disabled = false
}: DropdownItemProps) => {
  const iconAndTextColor = destructive ? TextIconColor.DESTRUCTIVE : TextIconColor.PRIMARY;
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!disabled) {
      onClick();
    }
  };
  return <StyledDropdownItem $destructive={destructive} $disabled={disabled} onClick={handleClick}>
      {icon && <Icons color={iconAndTextColor} icon={icon} />}
      <Typography color={iconAndTextColor} font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL}>
        {title}
      </Typography>
    </StyledDropdownItem>;
};
export default DropdownItem;