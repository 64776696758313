import { z } from 'zod';

import { WorkflowIntegrationActionStepType } from '../../constants';

export const WORKFLOW_INTEGRATION_ACTIONS_REQUIRING_ADDITIONAL_CONTEXT = [
  WorkflowIntegrationActionStepType.UPDATE_TICKET_PRIORITY,
  WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE
];

/*
 * Workflow Action Step Configs
 */
export const zAdditionalContextConfig = z.object({
  additionalContext: z.string().optional(),
  includeChatHistory: z.boolean(),
  commentActionOutput: z.boolean()
});

export type AdditionalContextConfig = z.infer<typeof zAdditionalContextConfig>;

/** Action step type to config payload mapping */
export const Z_WORKFLOW_INTEGRATION_ACTION_STEP_CONFIGS = {
  [WorkflowIntegrationActionStepType.UPDATE_TICKET_PRIORITY]:
    zAdditionalContextConfig,
  [WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET]: z.null(),
  [WorkflowIntegrationActionStepType.UPDATE_TICKET_ASSIGNEE]:
    zAdditionalContextConfig
} as const satisfies Record<WorkflowIntegrationActionStepType, z.ZodType>;

export const zWorkflowActionStepConfig = z.union([
  zAdditionalContextConfig,
  z.null()
]);

export type WorkflowActionStepConfig<
  T extends WorkflowIntegrationActionStepType
> = z.infer<(typeof Z_WORKFLOW_INTEGRATION_ACTION_STEP_CONFIGS)[T]>;

/** Type Guards */
export function isAdditionalContextConfig(
  config: WorkflowActionStepConfig<WorkflowIntegrationActionStepType>
): config is AdditionalContextConfig {
  return (
    config !== null &&
    'additionalContext' in config &&
    typeof config.additionalContext === 'string'
  );
}
