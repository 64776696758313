import _styled from "styled-components";
import { useState } from 'react';
import { Button, ButtonType } from '../Button';
import { TooltipPlacement } from '../ToolTip/ToolTip';
import SelectOptionComponent from './SelectOption';
import { Icon, TextField, TextFieldType, TextIconColor, TypographySize, ToolTip, ToolTipColor } from '~/components/core';
import { Dropdown } from '~/components/Dropdown';
const TextFieldContainer = _styled.div({
  "padding": "0.25rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem"
});
export interface SelectOption<Value extends string | null = string> {
  label: string;
  value: Value;
  description?: string;
  destructive?: boolean;
  disabled?: boolean;
  endIcon?: {
    icon: Icon;
    color?: TextIconColor;
  };
  onClick?: (value: Value) => void;
  prefix?: string;
  startIcon?: {
    icon: Icon;
    color?: TextIconColor;
  };
  suffix?: {
    text: string;
    color?: TextIconColor;
    size?: TypographySize;
  };
  tooltip?: string;
}
type SelectProps<Value extends string | null = string> = {
  onChange: (value: Value) => void;
  options: SelectOption<Value>[];
  placeholderLabel: string;
  value: Value;
  allowInput?: boolean;
  'data-id'?: string | undefined;
  disabled?: boolean;
  hideChevron?: boolean;
  inputPlaceholder?: string;
  prefix?: string;
  reservedValues?: string[];
  startIcon?: Icon | undefined;
  tooltip?: string | undefined;
  type?: ButtonType;
};
const Select = <Value extends string | null = string,>({
  onChange,
  options,
  value,
  placeholderLabel,
  allowInput = false,
  'data-id': dataId,
  disabled,
  inputPlaceholder,
  startIcon,
  prefix,
  reservedValues,
  type = ButtonType.OUTLINED,
  hideChevron = false,
  tooltip
}: SelectProps<Value>) => {
  const [inputValue, setInputValue] = useState<string>('');
  const selectedOption = options.find(option => option.value === value);

  // If the value was typed as the input value and does not exist as an option,
  // use the typed value as the label
  const label = value && !selectedOption ? value : selectedOption?.label || placeholderLabel;
  const handleClickSelect = (newValue: Value) => {
    setInputValue('');
    onChange(newValue);
  };
  const handleInputOnEnter = () => {
    // If Enter is pressed and new values are allowed, select the input value
    if (allowInput && !reservedValues?.includes(inputValue)) {
      onChange(inputValue as Value);
    }
  };
  return <Dropdown disabled={!!disabled} trigger={<Button data-id={dataId} endIcon={!hideChevron ? Icon.CHEVRON_DOWN : undefined} label={(prefix ?? '') + label} onClick={() => {}} startIcon={startIcon} tooltip={tooltip ?? ''} type={type} />}>
      {allowInput && <TextFieldContainer>
          <TextField autoFocus fullWidth onChange={v => setInputValue(v)} onEnter={() => handleInputOnEnter()} placeholder={inputPlaceholder ?? ''} size={TypographySize.SMALL} type={TextFieldType.SIMPLE} value={inputValue} />
        </TextFieldContainer>}
      {options.map(option => <ToolTip key={option.value} color={ToolTipColor.BLUE} content={option.tooltip ?? ''} offset={[0, 8]} placement={TooltipPlacement.RIGHT}>
          <div key={option.value} data-close-on-click="true">
            <SelectOptionComponent<Value> key={option.value} description={option.description} destructive={option.destructive ?? false} disabled={option.disabled ?? false} endIcon={option.endIcon} isSelected={option.value === value} label={option.label} onClick={option.onClick ?? handleClickSelect} prefix={option.prefix ?? ''} startIcon={option.startIcon} suffix={option.suffix ?? {
          text: ''
        }} value={option.value} />
          </div>
        </ToolTip>)}
    </Dropdown>;
};
export default Select;