import _styled from "styled-components";
import { Size } from '../constants';
import { TabNavigatorSize, TabNavigatorType } from './TabNavigator.consts';
import { TabNavigatorOption, TabOption } from './TabNavigatorOption';
const TabNavigatorContainer = _styled.div<{
  $orientation: TabNavigatorType;
  $tabSize: TabNavigatorSize;
}>(({
  $orientation,
  $tabSize
}) => [{
  "display": "flex"
}, $orientation === TabNavigatorType.VERTICAL && {
  "flexShrink": "0",
  "flexDirection": "column",
  "gap": "0.5rem"
}, $orientation === TabNavigatorType.HORIZONTAL && {
  "width": "fit-content",
  "flexDirection": "row",
  "justifyContent": "center",
  "gap": "2rem"
}, $orientation === TabNavigatorType.HORIZONTAL && $tabSize === Size.SMALL && {
  "display": "inline-flex",
  "alignItems": "center",
  "borderBottomWidth": "0.063rem",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(150 148 181 / var(--tw-border-opacity))"
}]);
const TabNavigatorOptionContainer = _styled.div<{
  $orientation: TabNavigatorType;
}>(({
  $orientation
}) => [{
  "display": "flex"
}, $orientation === TabNavigatorType.VERTICAL && {
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "paddingRight": "4rem"
}]);
interface TabNavigatorProps<TabValue extends string> {
  activeTab: TabValue;
  setActiveTab: (tab: TabValue) => void;
  tabOptions: TabOption<TabValue>[];
  orientation?: TabNavigatorType;
  size?: TabNavigatorSize;
}
const TabNavigator = <TabValue extends string,>({
  activeTab,
  tabOptions,
  setActiveTab,
  size = Size.LARGE,
  orientation = TabNavigatorType.HORIZONTAL
}: TabNavigatorProps<TabValue>) => <TabNavigatorContainer $orientation={orientation} $tabSize={size}>
    {tabOptions.map(tabOption => <TabNavigatorOptionContainer key={tabOption.value} $orientation={orientation}>
        <TabNavigatorOption<TabValue> isSelected={tabOption.value === activeTab} onSelect={newTab => setActiveTab(newTab)} size={size} tabOption={tabOption} />
      </TabNavigatorOptionContainer>)}
  </TabNavigatorContainer>;
export default TabNavigator;