/* eslint-disable no-param-reassign */
import {
  SupportedLlm,
  Llm,
  WorkflowStepType,
  WorkflowIntegrationActionStepType,
  WorkflowSupportedIntegration,
  WorkflowActionStepConfig
} from '@kindo/universal';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FileResource } from '~/components/Chat/AddFileModal';
import { WorkflowStep } from '~/types';
import {
  BuilderWorkflowIntegrationActionStep,
  BuilderWorkflowLlmStep,
  BuilderWorkflowStatus,
  BuilderWorkflowStep,
  BuilderWorkflowStepStaticContent,
  IntegrationWorkflowBuilderStepInput,
  isIntegrationActionWorkflowBuilderStep,
  isIntegrationWorkflowBuilderStepInput,
  isLlmWorkflowBuilderStep,
  isPromptTemplateWorkflowBuilderStepInput,
  PromptTemplateWorkflowBuilderStepInput,
  transformWorkflowStepToBuilderWorkflowStep
} from '~/types/workflowBuilder.types';

export interface BuilderWorkflowConfig {
  description: string;
  instructions: string;
  name: string;
  status: BuilderWorkflowStatus;
}

export interface BuilderWorkflowKnowledgeStore {
  content: FileResource[];
  status: BuilderWorkflowStatus;
}

export const DEFAULT_WORKFLOW_STEP_MODEL = Llm.CLAUDE_3_5_SONNET;

// Mustache template value for new user input, inserted as {{newUserInput}}
// Ensure that users can't create a template (e.g. through a user input) with this value
export const NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE = 'newUserInput';

// Mustache template value for new static content, inserted as {{newStaticContent}}
// Ensure that users can't create a template (e.g. through a user input) with this value
export const NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE = 'newStaticContent';

const NEW_EMPTY_WORKFLOW_LLM_STEP: BuilderWorkflowLlmStep = {
  type: WorkflowStepType.LLM,
  id: '',
  model: DEFAULT_WORKFLOW_STEP_MODEL,
  promptTemplate: '',
  status: BuilderWorkflowStatus.NEW,
  stepNumber: 0,
  staticContent: [],
  inputs: [],
  displayName: null,
  integrationInputActiveInEditor: null
};

const NEW_EMPTY_WORKFLOW_ACTION_STEP: BuilderWorkflowIntegrationActionStep = {
  type: WorkflowStepType.INTEGRATION_ACTION,
  id: '',
  status: BuilderWorkflowStatus.NEW,
  stepNumber: 0,
  integration: null,
  displayName: null,
  action: null,
  inputs: [],
  config: null
};

const NEW_EMPTY_WORKFLOW_CONFIG: BuilderWorkflowConfig = {
  description: '',
  instructions: '',
  name: '',
  status: BuilderWorkflowStatus.NEW
};

const NEW_EMPTY_KNOWLEDGE_STORE: BuilderWorkflowKnowledgeStore = {
  content: [],
  status: BuilderWorkflowStatus.NEW
};

interface WorkflowBuilderState {
  activeStep: BuilderWorkflowStep | null;
  knowledgeStore: BuilderWorkflowKnowledgeStore;
  workflowConfig: BuilderWorkflowConfig;
}

const initialState: WorkflowBuilderState = {
  activeStep: null,
  workflowConfig: NEW_EMPTY_WORKFLOW_CONFIG,
  knowledgeStore: NEW_EMPTY_KNOWLEDGE_STORE
};

export const workflowBuilderSlice = createSlice({
  name: 'workflowBuilder',
  initialState,
  reducers: {
    // Update prompt (mustache template)
    updateActiveStepPrompt: (state, action: PayloadAction<string>) => {
      if (!state.activeStep || state.activeStep.type !== WorkflowStepType.LLM)
        return;

      state.activeStep.promptTemplate = action.payload;

      if (state.activeStep.status === BuilderWorkflowStatus.SAVED) {
        state.activeStep.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    updateActiveStepStepNumber: (
      state,
      action: PayloadAction<{ stepNumber: number }>
    ) => {
      if (!state.activeStep) return;

      state.activeStep = {
        ...state.activeStep,
        stepNumber: action.payload.stepNumber
      };

      if (state.activeStep.status === BuilderWorkflowStatus.SAVED) {
        state.activeStep.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    // Update LLM Model
    updateActiveStepModel: (state, action: PayloadAction<SupportedLlm>) => {
      if (!state.activeStep || !isLlmWorkflowBuilderStep(state.activeStep))
        return;

      state.activeStep.model = action.payload;

      if (state.activeStep.status === BuilderWorkflowStatus.SAVED) {
        state.activeStep.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    // Update Integration, action, input, and config for Integration Action Step
    updateActiveStepIntegration: (
      state,
      action: PayloadAction<{ integration: WorkflowSupportedIntegration }>
    ) => {
      if (
        !state.activeStep ||
        !isIntegrationActionWorkflowBuilderStep(state.activeStep)
      )
        return;
      state.activeStep.integration = action.payload.integration;

      if (state.activeStep.status === BuilderWorkflowStatus.SAVED) {
        state.activeStep.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    updateActiveStepAction: (
      state,
      action: PayloadAction<{
        integrationAction: WorkflowIntegrationActionStepType;
      }>
    ) => {
      if (
        !state.activeStep ||
        !isIntegrationActionWorkflowBuilderStep(state.activeStep)
      )
        return;

      state.activeStep.action = action.payload.integrationAction;

      if (state.activeStep.status === BuilderWorkflowStatus.SAVED) {
        state.activeStep.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    updateActiveStepActionConfig: (
      state,
      action: PayloadAction<{
        config: WorkflowActionStepConfig<WorkflowIntegrationActionStepType>;
      }>
    ) => {
      if (
        !state.activeStep ||
        !isIntegrationActionWorkflowBuilderStep(state.activeStep)
      )
        return;

      state.activeStep.config = action.payload.config;

      if (state.activeStep.status === BuilderWorkflowStatus.SAVED) {
        state.activeStep.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    // Update Workflow Config Description
    updateWorkflowConfigDescription: (state, action: PayloadAction<string>) => {
      if (!state.workflowConfig) return;

      state.workflowConfig.description = action.payload;

      if (state.workflowConfig.status !== BuilderWorkflowStatus.MODIFIED) {
        state.workflowConfig.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    // Update Workflow Config Instructions
    updateWorkflowConfigInstructions: (
      state,
      action: PayloadAction<string>
    ) => {
      if (!state.workflowConfig) return;

      state.workflowConfig.instructions = action.payload;

      if (state.workflowConfig.status !== BuilderWorkflowStatus.MODIFIED) {
        state.workflowConfig.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    // Update Workflow Config Name
    updateWorkflowConfigName: (state, action: PayloadAction<string>) => {
      if (!state.workflowConfig) return;

      state.workflowConfig.name = action.payload;

      if (state.workflowConfig.status !== BuilderWorkflowStatus.MODIFIED) {
        state.workflowConfig.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    // Update Knowledge Store Content
    updateKnowledgeStoreContent: (
      state,
      action: PayloadAction<FileResource[]>
    ) => {
      if (!state.knowledgeStore) return;

      state.knowledgeStore.content = action.payload;

      if (state.knowledgeStore.status !== BuilderWorkflowStatus.MODIFIED) {
        state.knowledgeStore.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    // Hydrate Workflow Config and Knowledge Store
    hydrateWorkflowConfigAndKnowledgeStore: (
      state,
      action: PayloadAction<{
        knowledgeStore: Omit<BuilderWorkflowKnowledgeStore, 'status'>;
        workflowConfig: Omit<BuilderWorkflowConfig, 'status'>;
      }>
    ) => {
      state.workflowConfig = {
        ...action.payload.workflowConfig,
        status: BuilderWorkflowStatus.SAVED
      };
      state.knowledgeStore = {
        ...action.payload.knowledgeStore,
        status: BuilderWorkflowStatus.SAVED
      };
    },

    // Insert new user input as empty mustache template
    // This is picked up and turned into a node by LexicalUpdateOnReduxChangePlugin
    insertNewUserInput: (state) => {
      if (!state.activeStep || !isLlmWorkflowBuilderStep(state.activeStep))
        return;

      state.activeStep.promptTemplate += `{{${NEW_USER_INPUT_MUSTACHE_TEMPLATE_VALUE}}}`;

      if (state.activeStep.status === BuilderWorkflowStatus.SAVED) {
        state.activeStep.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    // Insert new static content as {{newStaticContent}}}
    // This is picked up and turned into a node by LexicalUpdateOnReduxChangePlugin
    insertNewStaticContent: (state) => {
      if (!state.activeStep || !isLlmWorkflowBuilderStep(state.activeStep))
        return;

      state.activeStep.promptTemplate += `{{${NEW_STATIC_CONTENT_MUSTACHE_TEMPLATE_VALUE}}}`;

      if (state.activeStep.status === BuilderWorkflowStatus.SAVED) {
        state.activeStep.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    // Add a new user input
    addUserInput: (
      state,
      action: PayloadAction<PromptTemplateWorkflowBuilderStepInput>
    ) => {
      if (!state.activeStep || !isLlmWorkflowBuilderStep(state.activeStep))
        return;

      const isDuplicateInput = state.activeStep.inputs
        .filter(isPromptTemplateWorkflowBuilderStepInput)
        .some(
          (input) =>
            input.templateResolutionName ===
            action.payload.templateResolutionName
        );

      if (isDuplicateInput) return;

      state.activeStep.inputs.push(action.payload);
    },

    // Add a new static content
    addStaticContent: (
      state,
      action: PayloadAction<BuilderWorkflowStepStaticContent>
    ) => {
      if (!state.activeStep || !isLlmWorkflowBuilderStep(state.activeStep))
        return;

      const isDuplicateContent = state.activeStep.staticContent.some(
        (content) => content.id === action.payload.id
      );

      if (isDuplicateContent) return;

      state.activeStep.staticContent.push(action.payload);
    },

    // Add a new integration input
    addIntegrationInput: (
      state,
      action: PayloadAction<{ input: IntegrationWorkflowBuilderStepInput }>
    ) => {
      if (!state.activeStep) return;
      // If integration action step, then overwrite existing integration input
      // bc action step only supports one integration input
      if (isIntegrationActionWorkflowBuilderStep(state.activeStep)) {
        state.activeStep.inputs = [action.payload.input];
      }
      // Else if llm step, then check if integration input already exists
      // If duplicate input and specified fields are the same, do nothing and return
      // If duplicate input w/ different specified fields, remove previous integration input
      // Add new integration input
      else if (isLlmWorkflowBuilderStep(state.activeStep)) {
        const existingWorkflowStepInput = state.activeStep.inputs
          .filter(isIntegrationWorkflowBuilderStepInput)
          .find((input) => input.id === action.payload.input.id);
        if (existingWorkflowStepInput) {
          const currentSpecifiedFields =
            action.payload.input.config?.specifiedFields ?? [];
          if (
            currentSpecifiedFields.length ===
              (existingWorkflowStepInput.config?.specifiedFields?.length ??
                0) &&
            currentSpecifiedFields.every((element) =>
              (
                existingWorkflowStepInput.config?.specifiedFields ?? []
              ).includes(element)
            )
          )
            return;
          state.activeStep.inputs = state.activeStep.inputs.filter(
            (input) =>
              !isIntegrationWorkflowBuilderStepInput(input) ||
              input.id !== action.payload.input.id
          );
        }
        state.activeStep.inputs.push(action.payload.input);
      }
      if (state.activeStep.status === BuilderWorkflowStatus.SAVED) {
        state.activeStep.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    // Remove an existing integration input from active workflow llm step
    removeIntegrationInput: (
      state,
      action: PayloadAction<{ inputId: string }>
    ) => {
      if (!state.activeStep || !isLlmWorkflowBuilderStep(state.activeStep))
        return;
      state.activeStep.inputs = state.activeStep.inputs.filter(
        (input) =>
          !isIntegrationWorkflowBuilderStepInput(input) ||
          input.id !== action.payload.inputId
      );
    },

    // Set active integration input for active workflow llm step
    setIntegrationInputActiveInEditor: (
      state,
      action: PayloadAction<{ inputId: string }>
    ) => {
      if (!state.activeStep || !isLlmWorkflowBuilderStep(state.activeStep))
        return;
      const newActiveInput = state.activeStep.inputs
        .filter(isIntegrationWorkflowBuilderStepInput)
        .find((input) => input.id === action.payload.inputId);
      if (!newActiveInput) {
        console.error(
          `No integration input found with id: ${action.payload.inputId}`
        );
        return;
      }
      state.activeStep.integrationInputActiveInEditor = newActiveInput;
    },

    // Set active integration input in WorkflowIntegrationInputBuilder
    clearIntegrationInputActiveInEditor: (state) => {
      if (!state.activeStep || !isLlmWorkflowBuilderStep(state.activeStep))
        return;
      state.activeStep.integrationInputActiveInEditor = null;
    },

    // Start editing existing step
    setExistingStepActive: (state, action: PayloadAction<WorkflowStep>) => {
      const newActiveStep = transformWorkflowStepToBuilderWorkflowStep(
        action.payload,
        BuilderWorkflowStatus.SAVED
      );

      state.activeStep = newActiveStep;
    },

    // Create a new step
    createNewStep: (state, action: PayloadAction<{ stepNumber: number }>) => {
      state.activeStep = {
        ...NEW_EMPTY_WORKFLOW_LLM_STEP,
        stepNumber: action.payload.stepNumber
      };
    },

    createNewActionStep: (
      state,
      action: PayloadAction<{ stepNumber: number }>
    ) => {
      state.activeStep = {
        ...NEW_EMPTY_WORKFLOW_ACTION_STEP,
        stepNumber: action.payload.stepNumber
      };
    },

    // Create a new workflow config
    createNewWorkflowConfig: (state) => {
      state.workflowConfig = NEW_EMPTY_WORKFLOW_CONFIG;
    },

    // Create a step with pre-populated data, used to duplicate a step
    createNewPrePopulatedStep: (
      state,
      action: PayloadAction<{
        prePopulatedStep: WorkflowStep;
      }>
    ) => {
      const { prePopulatedStep } = action.payload;

      const newActiveStep = transformWorkflowStepToBuilderWorkflowStep(
        prePopulatedStep,
        BuilderWorkflowStatus.NEW
      );

      state.activeStep = newActiveStep;
    },

    // Save
    markActiveStepSaved: (state) => {
      if (!state.activeStep) return;

      state.activeStep = null;
    },

    markWorkflowConfigSaved: (state) => {
      if (!state.workflowConfig) return;

      state.workflowConfig.status = BuilderWorkflowStatus.SAVED;
    },

    markKnowledgeStoreSaved: (state) => {
      if (!state.knowledgeStore) return;

      state.knowledgeStore.status = BuilderWorkflowStatus.SAVED;
    },

    // Cancel
    clearActiveStep: (state) => {
      state.activeStep = null;
    }
  }
});

export const workflowBuilderActions = workflowBuilderSlice.actions;
