import { useState } from 'react';
import { AddFileModal, FileResource } from '../../Chat/AddFileModal';
import { TextIconColor, Icon, IconButton, IconButtonType, Size } from '~/components/core';
import { LocalStorageKey } from '~/constants';
import useLocalStorage from '~/hooks/useLocalStorage';
type AddFilesButtonProps = {
  disabled: boolean;
  onFileSelect: (files: FileResource[]) => void;
  selectedFiles: FileResource[];
  'data-id'?: string;
};
const AddFilesButton = ({
  disabled,
  onFileSelect,
  'data-id': dataId,
  selectedFiles
}: AddFilesButtonProps) => {
  // State
  const [showAddFileModal, setShowAddFileModal] = useState<boolean>(false);

  // Custom hooks
  const [chatModel] = useLocalStorage(LocalStorageKey.CHAT_LLM);
  return <>
      <IconButton color={TextIconColor.SECONDARY} data-id={dataId ?? ''} disabled={disabled} icon={Icon.PLUS} onClick={() => setShowAddFileModal(true)} size={Size.LARGE} type={IconButtonType.SIMPLE} />
      {showAddFileModal && <AddFileModal activeModel={chatModel} initialSelectedFiles={selectedFiles} onClose={() => {
      setShowAddFileModal(false);
    }} onSelect={onFileSelect} />}
    </>;
};
export default AddFilesButton;