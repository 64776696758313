import _styled from "styled-components";
import { CHAT_AGENT_TOOLS, ContentMode, FileType, isImageFileType, Tool, TOOL_DESCRIPTIONS, TOOL_DISPLAY_NAMES } from '@kindo/universal';
import { useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import useAutoGrow from '../../hooks/useAutoGrow';
import { FileResource } from '../Chat/AddFileModal';
import { Chip, TextIconColor, Icon, Icons, LoadingSpinner, LoadingSpinnerSize, Select, Size, Toggle, ToolTip, Typography, TypographySize, TEXT_COLORS, Button, ButtonType, IconButton, IconButtonType, TypographyFont } from '../core';
import { typographyFont } from '../core/Typography/Typography';
import { Dropdown, DropdownPlacement } from '../Dropdown';
import { DlpFiltersConfig } from '../SecuritySettings/OrgSecurityControls/DlpFilters/DlpFiltersConfig/DlpFilters.types';
import { ImageFilePreview } from '../shared';
import { ModelSelect } from '../shared/ModelSelect';
import { AddFilesButton } from './AddFilesButton';
import { LoadingStopButton } from './LoadingStopButton';
import { LocalStorageKey } from '~/constants';
import { useAppSelector } from '~/hooks';
import useLocalStorage from '~/hooks/useLocalStorage';
import { chatActions } from '~/redux/reducers/chatSlice';
import { Workflow } from '~/types';
const PromptBarContainer = _styled.div(() => [{
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.75rem"
}, isMobileOnly && {
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem"
}]);
const PromptBarInputBorderContainer = _styled.div<{
  $focused: boolean;
}>(({
  $focused
}) => [{
  "position": "relative",
  "width": "100%",
  "borderRadius": "2px",
  "padding": "1px"
}, $focused ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(141 118 255 / var(--tw-bg-opacity))"
} : {
  "background": "linear-gradient(to right, #806BEE 0%, #444460 100%)",
  "&:hover": {
    "background": "linear-gradient(to right, #9D8BFF 0%, #5F5F85 100%)"
  }
}]);
const PromptBarInputContainer = _styled.div<{
  disabled: boolean;
  focused: boolean;
}>(({
  disabled,
  focused
}) => [{
  "position": "relative",
  "width": "100%",
  "borderRadius": "0.125rem",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "100ms"
}, focused ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(0 0 0 / var(--tw-bg-opacity))",
  "backdropFilter": "blur(5.5px)"
} : {
  "background": "linear-gradient(269deg, rgba(0, 0, 0, 0.00) 78.88%, rgba(53, 14, 205, 0.55) 100.32%), rgba(0, 0, 0, 0.89)"
}, disabled && {
  "cursor": "not-allowed"
}]);
const PromptBarConfigContainer = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "gap": "1rem"
});
const PromptBarConfigSelectContainer = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem"
});
const ToggleContainer = _styled.div({
  "display": "flex",
  "gap": "0.5rem"
});
const ToolsDropdownContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const SelectedFilesContainer = _styled.div({
  "position": "relative",
  "display": "flex",
  "alignItems": "center",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
const SelectedFilesHoverGroup = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "0.5rem"
});
const AndOthersContainer = _styled.div({
  "display": "flex",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-x-reverse": "0",
    "marginRight": "calc(0.5rem * var(--tw-space-x-reverse))",
    "marginLeft": "calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))"
  },
  "overflow": "hidden",
  "textOverflow": "ellipsis"
});
const FileChipContainer = _styled.div({
  "marginBottom": "0.25rem"
});
const AllFilesContainer = _styled.div({
  "position": "absolute",
  "bottom": "0px",
  "display": "none",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const PromptBarInputFlexContainer = _styled.div<{
  $hasContent: boolean;
}>(({
  $hasContent
}) => [{
  "position": "relative",
  "display": "flex",
  "height": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.5rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "transitionProperty": "padding",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "75ms"
}, $hasContent && {
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
}]);
const PromptBarInputTextContainer = _styled.div({
  "display": "flex",
  "flex": "1 1 0%",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "0.5rem"
});
const PromptBarInputButtonsContainer = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "gap": "1rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
const PromptSubmitButton = _styled.button<{
  disabled: boolean;
}>(({
  disabled
}) => [{
  "height": "100%",
  "cursor": "pointer",
  "alignItems": "center"
}, disabled && {
  "cursor": "not-allowed"
}]);
const StyledTextArea = _styled.textarea<{
  $empty: boolean;
  disabled: boolean;
  value: string;
}>(({
  $empty,
  disabled = false
}) => [
// text-base is 16px, so mobile doesn't zoom in
{
  "flex": "1 1 0%",
  "cursor": "text",
  "resize": "none",
  "backgroundColor": "transparent",
  "paddingTop": "0.75rem",
  "paddingBottom": "0.75rem",
  "fontSize": "1rem",
  "lineHeight": "1.5rem",
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
}, TEXT_COLORS[TextIconColor.PRIMARY], typographyFont[TypographyFont.HEADING], disabled && {
  "cursor": "not-allowed"
}, $empty && {
  "overflow": "hidden"
}, {
  '&::placeholder': {
    color: `${TEXT_COLORS[TextIconColor.PRIMARY_HIGHLIGHT].color}`
  },
  '&:focus::placeholder': {
    color: `${TEXT_COLORS[TextIconColor.SLATE].color}`
  }
}]);
interface PromptBarProps {
  maxHeight: number;
  onStopGeneration: () => void;
  onSubmit: (args: {
    message: string;
    selectedFiles: FileResource[];
  }) => void;
  autoFocus?: boolean;
  disableTextInput?: boolean;
  disabledSubmit?: boolean;
  dlpFilters?: DlpFiltersConfig | undefined;
  loading?: boolean;
  placeholder?: string;
  tooltip?: string | undefined;
  workflow?: Workflow | undefined;
}
export const PromptBar = ({
  onSubmit,
  onStopGeneration,
  disabledSubmit = false,
  disableTextInput = false,
  dlpFilters,
  placeholder = 'Type to chat, find, or create anything',
  loading = false,
  maxHeight,
  autoFocus = false,
  tooltip,
  workflow
}: PromptBarProps) => {
  // State
  const [value, setValue] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<FileResource[]>([]);
  // Redux
  const dispatch = useDispatch();
  const setContentMode = (contentMode: ContentMode) => dispatch(chatActions.setContentMode({
    contentMode
  }));
  const {
    contentMode
  } = useAppSelector(state => state.chat);
  const setSelectedFilesAndContentMode = (newSelectedFiles: FileResource[]) => {
    setSelectedFiles(newSelectedFiles);
    if (newSelectedFiles.length === 0 || newSelectedFiles.filter(file => file.fileType === FileType.CSV).length === 0) {
      setContentMode(ContentMode.ALL);
    }
  };

  // Ref
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  // Custom Hooks
  const [chatModel, setChatModel] = useLocalStorage<LocalStorageKey.CHAT_LLM>(LocalStorageKey.CHAT_LLM);
  const [enabledTools, setEnabledTools] = useLocalStorage<LocalStorageKey.ENABLED_TOOLS>(LocalStorageKey.ENABLED_TOOLS);

  // Constants
  const enabledToolsArray = JSON.parse(enabledTools);
  const toolDisabled = (tool: Tool) => tool === Tool.URL_SCRAPE && dlpFilters?.url === 'REDACT';
  const getToolTipContent = (tool: Tool): string | undefined => {
    if (tool === Tool.URL_SCRAPE && dlpFilters?.url === 'REDACT') {
      return "URL is redacted by your organization's DLP settings";
    }
    return TOOL_DESCRIPTIONS[tool];
  };
  useEffect(() => {
    textAreaRef.current?.focus();
  }, [selectedFiles.length]);
  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
  };
  // TODO: Delete this hook once we replace with TextField component,
  // which has auto-grow automatically
  useAutoGrow(textAreaRef.current, value, 0, maxHeight);
  const handleSubmit = () => {
    if (!value || loading || disabledSubmit) {
      return;
    }

    // Blur the textarea to dismiss mobile keyboard
    textAreaRef.current?.blur();
    onSubmit({
      message: value,
      selectedFiles
    });
    setValue('');
    setSelectedFilesAndContentMode([]);

    // Maintain focus on the prompt bar so user can send next message
    // Only focus if not on mobile to prevent keyboard from popping up again
    if (!isMobileOnly) {
      textAreaRef.current?.focus();
    }
  };
  const handleToolToggle = (tool: Tool) => {
    const isToolEnabled = enabledToolsArray.includes(tool);
    if (isToolEnabled) {
      const updatedToolsArray = enabledToolsArray.filter((enabledTool: Tool) => enabledTool !== tool);
      setEnabledTools(JSON.stringify(updatedToolsArray));
    } else {
      enabledToolsArray.push(tool);
      setEnabledTools(JSON.stringify(enabledToolsArray));
    }
  };
  const handleKeyDown = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // If user presses backspace without any text, remove the last file
    if (evt.key === 'Backspace' && !evt.currentTarget.value) {
      setSelectedFilesAndContentMode(selectedFiles.slice(0, selectedFiles.length - 1));
    }

    // If user presses enter without shift, submit the form
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault();
      evt.stopPropagation();
      handleSubmit();
    }
  };
  const oneCsvSelected = selectedFiles.filter(file => file.fileType === FileType.CSV).length === 1;
  const areMultipleFilesSelected = selectedFiles.length > 1;
  const isContentModeSelectDisabled = !areMultipleFilesSelected && !oneCsvSelected;

  // Add focus state
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  return <PromptBarContainer>
      <PromptBarConfigContainer>
        <PromptBarConfigSelectContainer>
          <ModelSelect chatModel={chatModel} onChange={setChatModel} placeholderLabel="Select AI Model to Chat" prefix="Chat Model: " />
          {!isMobileOnly && <>
              <Select<ContentMode> disabled={isContentModeSelectDisabled} onChange={(val: ContentMode) => {
            setContentMode(val);
          }} options={[{
            label: 'For all files',
            value: ContentMode.ALL,
            tooltip: 'Get one response for all files.\nAll rows of the CSV are used at once.'
          }, {
            disabled: !areMultipleFilesSelected,
            label: 'On each file',
            value: ContentMode.PARALLEL,
            tooltip: 'Get a response for each file'
          }, {
            disabled: !oneCsvSelected,
            label: 'CSV Row-By-Row',
            value: ContentMode.CSV_ROW_BY_ROW,
            tooltip: 'Get a response for each row in the CSV.\nAdditional attached files will be used as context.'
          }]} placeholderLabel="Select single response for all files, for each file, or for each row in a CSV" tooltip={isContentModeSelectDisabled ? 'Select multiple files to switch file chat mode' : ''} value={contentMode} />
              <Dropdown placement={DropdownPlacement.TOP_START} trigger={<Button endIcon={Icon.GROUP} label="Tools" onClick={() => {}} type={ButtonType.OUTLINED} />}>
                <ToolsDropdownContainer>
                  {CHAT_AGENT_TOOLS.map((tool: Tool) => <ToolTip key={tool} content={getToolTipContent(tool)}>
                      <ToggleContainer key={tool}>
                        <Toggle checked={enabledToolsArray.includes(tool) && !toolDisabled(tool)} disabled={toolDisabled(tool)} onToggle={() => handleToolToggle(tool)} size={Size.SMALL} />
                        <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL}>
                          {TOOL_DISPLAY_NAMES[tool]}
                        </Typography>
                      </ToggleContainer>
                    </ToolTip>)}
                </ToolsDropdownContainer>
              </Dropdown>
            </>}
        </PromptBarConfigSelectContainer>
        {!!selectedFiles.length && <SelectedFilesContainer>
            {/* TODO: Precise implementation of file chip hover group is awaiting henry's input */}
            <SelectedFilesHoverGroup className="group/promptbar-files">
              {selectedFiles.slice(0, 1)?.map(file => isImageFileType(file.fileType) ? <ImageFilePreview key={file.id} fileId={file.id} fileType={file.fileType} /> : <FileChipContainer key={file.id} css={{
            "transitionProperty": "opacity",
            "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
            "transitionDuration": "150ms",
            ".group\\/promptbar-files:hover &": {
              "opacity": "0"
            }
          }}>
                    <Chip key={file.id} label={file.name} onClose={() => {
              setSelectedFilesAndContentMode(selectedFiles.filter(f => f.id !== file.id));
            }} startIcon={Icon.DOCUMENT} />
                  </FileChipContainer>)}
              {selectedFiles.length > 1 && <AndOthersContainer css={{
            "transitionProperty": "opacity",
            "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
            "transitionDuration": "150ms",
            ".group\\/promptbar-files:hover &": {
              "opacity": "0"
            }
          }}>
                  <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
                    and {selectedFiles.length - 1} others
                  </Typography>
                </AndOthersContainer>}
              <AllFilesContainer css={{
            ".group\\/promptbar-files:hover &": {
              "display": "block"
            }
          }}>
                {selectedFiles.map(file => <FileChipContainer key={file.id}>
                    <Chip key={file.id} label={file.name} onClose={() => {
                setSelectedFilesAndContentMode(selectedFiles.filter(f => f.id !== file.id));
              }} startIcon={Icon.DOCUMENT} />
                  </FileChipContainer>)}
              </AllFilesContainer>
            </SelectedFilesHoverGroup>
          </SelectedFilesContainer>}
      </PromptBarConfigContainer>
      <PromptBarInputBorderContainer $focused={isFocused}>
        <PromptBarInputContainer disabled={disabledSubmit} focused={isFocused}>
          <ToolTip content={tooltip ?? ''}>
            <PromptBarInputFlexContainer $hasContent={isFocused}>
              {!isMobileOnly && <AddFilesButton data-id="workflow_plus" disabled={disabledSubmit} onFileSelect={newSelected => {
              setSelectedFilesAndContentMode(newSelected);
              textAreaRef.current?.focus();
            }} selectedFiles={selectedFiles} />}
              <PromptBarInputTextContainer>
                <StyledTextArea ref={textAreaRef} $empty={!value} autoFocus={autoFocus} disabled={disableTextInput} name="query" onBlur={handleBlur} onChange={handleChange} onFocus={handleFocus} onKeyDown={handleKeyDown} placeholder={selectedFiles.length ? '' : placeholder} required rows={1} value={value} />
              </PromptBarInputTextContainer>
              <PromptBarInputButtonsContainer>
                {!loading && !!value && <IconButton icon={Icon.CLOSE} onClick={() => {
                setValue('');
                if (textAreaRef.current) {
                  textAreaRef.current.value = '';
                  textAreaRef.current.focus();
                }
              }} size={Size.MEDIUM} type={IconButtonType.SOLID} />}
                {!loading && <PromptSubmitButton disabled={disabledSubmit} onClick={handleSubmit}>
                    <Icons color={disabledSubmit || !textAreaRef.current?.value ? TextIconColor.DISABLED : TextIconColor.PRIMARY} icon={Icon.SEND} size={Size.LARGE} />
                  </PromptSubmitButton>}
                {/* If workflow is not defined, then we are in chat mode */}
                {loading && !workflow && <LoadingStopButton onStopClick={onStopGeneration} />}
                {/* If workflow is defined, then we are in workflow mode */}
                {/* TODO: Add capability for stopping workflow */}
                {loading && workflow && <LoadingSpinner size={LoadingSpinnerSize.LARGE} />}
              </PromptBarInputButtonsContainer>
            </PromptBarInputFlexContainer>
          </ToolTip>
        </PromptBarInputContainer>
      </PromptBarInputBorderContainer>
    </PromptBarContainer>;
};
export default PromptBar;