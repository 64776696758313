import _styled from "styled-components";
import { Typography, TypographySize, TypographyWeight, Icon, Icons, Size, TextIconColor, TypographyFont, TypographyCasing } from '../core';
const ToggleItemContainer = _styled.span<{
  $isSelected: boolean;
  $toggleIndex: number;
}>(({
  $isSelected,
  $toggleIndex
}) => [{
  "display": "inline-flex",
  "width": "10rem",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.5rem",
  "paddingLeft": "0.125rem",
  "paddingRight": "0.125rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "textAlign": "center",
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
}, $toggleIndex === 0 && {
  "borderTopLeftRadius": "0.375rem",
  "borderBottomLeftRadius": "0.375rem"
}, $toggleIndex === 2 && {
  "borderTopRightRadius": "0.375rem",
  "borderBottomRightRadius": "0.375rem"
}, $isSelected && {
  "cursor": "default"
}, $isSelected && {
  "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
  "--tw-gradient-from": "#320DC4 var(--tw-gradient-from-position)",
  "--tw-gradient-to": "#8544D9 var(--tw-gradient-to-position)",
  "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
  "--tw-shadow": "0 0 20px rgba(60, 0, 255, 1)",
  "--tw-shadow-colored": "0 0 20px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
}, !$isSelected && {
  "cursor": "pointer"
}]);
export interface ToggleOption<ToggleValue extends string> {
  label: string;
  value: ToggleValue;
  icon?: Icon;
  route?: string;
}
type ToggleItemProp<ToggleValue extends string> = {
  isSelected: boolean;
  toggleIndex: number;
  toggleOption: ToggleOption<ToggleValue>;
};
const ToggleItem = <ToggleValue extends string,>({
  toggleOption,
  isSelected,
  toggleIndex
}: ToggleItemProp<ToggleValue>) => <ToggleItemContainer $isSelected={isSelected} $toggleIndex={toggleIndex} aria-hidden>
    <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.PRIMARY} font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
      {toggleOption.label}
    </Typography>
    {!!toggleOption.icon && <Icons color={TextIconColor.PRIMARY} icon={toggleOption.icon} size={Size.MEDIUM} />}
  </ToggleItemContainer>;
export default ToggleItem;