import _styled from "styled-components";
import { TextIconColor } from '../core/constants';
import { Icon, Icons } from '../core/Icon';
import { Typography, TypographyCasing, TypographySize, TypographyWeight } from '../core/Typography';
export type TableSort<SortKey extends string = string> = {
  direction: 'asc' | 'desc';
  key: SortKey;
};
interface SortHeaderProps<SortKey extends string> {
  activeSort: TableSort<SortKey>;
  setSortKey: (value: TableSort<SortKey>) => void;
  sortKey: SortKey;
  title: string;
  color?: TextIconColor;
  disabled?: boolean;
}
const SortHeaderContainer = _styled.div({
  "display": "flex",
  "width": "fit-content",
  "cursor": "pointer",
  "userSelect": "none",
  "alignItems": "center",
  "gap": "0.25rem"
});
const SortIconContainer = _styled.span<{
  $isActive: boolean;
}>(({
  $isActive
}) => [$isActive ? {
  "visibility": "visible"
} : {
  "visibility": "hidden"
}]);
export const Header: React.FC<{
  title: string;
  color?: TextIconColor;
}> = ({
  title,
  color
}) => <Typography casing={TypographyCasing.UPPERCASE} color={color || TextIconColor.PRIMARY} size={TypographySize.X_SMALL} weight={TypographyWeight.NORMAL}>
    {title}
  </Typography>;
export const CsvHeader: React.FC<{
  title: string;
}> = ({
  title
}) => <Typography color={TextIconColor.PRIMARY} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD}>
    {title}
  </Typography>;
function SortHeader<SortKey extends string>({
  activeSort,
  sortKey,
  setSortKey,
  title,
  disabled,
  color = TextIconColor.PRIMARY
}: SortHeaderProps<SortKey>) {
  const isActive = activeSort.key === sortKey;
  const isDesc = activeSort.direction === 'desc';
  const onClick = () => {
    if (isActive) {
      setSortKey({
        key: sortKey,
        direction: isDesc ? 'asc' : 'desc'
      });
    } else {
      setSortKey({
        key: sortKey,
        direction: 'asc'
      });
    }
  };
  return <SortHeaderContainer onClick={!disabled ? onClick : undefined}>
      <Header color={color} title={title} />
      <SortIconContainer $isActive={isActive}>
        <Icons color={color} icon={isDesc ? Icon.CHEVRON_DOWN : Icon.CHEVRON_UP} />
      </SortIconContainer>
    </SortHeaderContainer>;
}
export default SortHeader;