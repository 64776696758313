import _styled from "styled-components";
import { FeatureFlags } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs';
import { ContentFilter, ContentFiltersValues, Filters } from 'components/shared/Filters';
import { debounce } from 'lodash';
import { useState } from 'react';
import { Button, ButtonType, Icon, TextField, TextFieldType } from '~/components/core';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
const SelectAndUploadContainer = _styled.div({
  "position": "relative",
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "0.5rem"
});
type FilterFilesFormProps = {
  filter: ContentFiltersValues;
  onDeselectAll: () => void;
  onUploadClick: () => void;
  refetchUploadedFiles: () => void;
  selectedFileCount: number;
  setFilter: (filter: ContentFiltersValues) => void;
};
const FilterFilesForm = ({
  filter,
  setFilter,
  onUploadClick,
  refetchUploadedFiles,
  selectedFileCount,
  onDeselectAll
}: FilterFilesFormProps) => {
  // State
  const [showYoutubeUrlModal, setShowYoutubeUrlModal] = useState<boolean>(false);
  const [youtubeUrlInputValue, setYoutubeUrlInputValue] = useState<string>('');

  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  const isYoutubeDownloadEnabled = useFlag(FeatureFlags.YOUTUBE_DOWNLOAD);

  // Mutations
  const createFileFromYoutubeMutation = nextTrpc.files.createFromYoutubeUrl.useMutation({
    onSuccess: () => {
      setShowYoutubeUrlModal(false);
      refetchUploadedFiles();
    },
    onError: () => {
      setShowYoutubeUrlModal(false);
      enqueueToast({
        message: 'Something went wrong while uploading the file from YouTube.',
        type: ToastType.ERROR
      });
    }
  });
  const handleUploadYoutubeUrl = () => createFileFromYoutubeMutation.mutate({
    youtubeUrl: youtubeUrlInputValue
  });
  const handleSearchChange = debounce((search: string) => {
    setFilter({
      ...filter,
      [ContentFilter.NAME]: search
    });
  }, 500);
  return <div css={{
    "position": "relative",
    "display": "flex",
    "flexDirection": "row",
    "justifyContent": "space-between",
    "paddingTop": "1rem",
    "paddingBottom": "1rem"
  }}>
      <Filters<ContentFilter>
    // TODO SQLINJECTION: Temporarily removed due to SQL injection vector
    // filters={[
    //   {
    //     defaultLabel: 'All',
    //     filterKey: ContentFilter.TYPE,
    //     options: acceptedFileTypes.map((fileType) => ({
    //       label: fileType,
    //       value: fileType
    //     })),
    //     placeholder: 'Type'
    //   },
    //   {
    //     defaultLabel: 'All',
    //     filterKey: ContentFilter.SOURCE,
    //     options: Object.keys(ContentSource).map((source) => ({
    //       label: source,
    //       value: source
    //     })),
    //     placeholder: 'Source'
    //   }
    // ]}
    filters={[]} filtersValues={filter} searchPlaceholder="SEARCH" searchValue={filter.name || ''} setFilters={newFilters => setFilter(newFilters)} setSearchValue={handleSearchChange} />

      <SelectAndUploadContainer>
        {selectedFileCount > 0 && onDeselectAll && <Button label={`Clear ${selectedFileCount} selected files`} onClick={onDeselectAll} startIcon={Icon.CLOSE} type={ButtonType.OUTLINED} />}
        {isYoutubeDownloadEnabled && <Button label="Upload from YouTube" onClick={() => setShowYoutubeUrlModal(true)} startIcon={Icon.PLAY} type={ButtonType.OUTLINED} />}
        <Button endIcon={Icon.UPLOAD} label="UPLOAD FILE" onClick={onUploadClick} type={ButtonType.SOLID} />
      </SelectAndUploadContainer>
      {showYoutubeUrlModal && <ConfirmationModal confirmButtonLabel="Upload" confirmLoading={createFileFromYoutubeMutation.isLoading} header="Upload a file from a YouTube URL" onCancel={() => setShowYoutubeUrlModal(false)} onConfirm={handleUploadYoutubeUrl} open subtext="The content of the video will be added to your Kindo library as an audio file, and can be used in chat and agents.">
          <TextField fullWidth onChange={value => setYoutubeUrlInputValue(value)} onEnter={handleUploadYoutubeUrl} placeholder="https://www.youtube.com/watch?v=BbcChAL-gAs" type={TextFieldType.HIGHLIGHT} value={youtubeUrlInputValue} />
        </ConfirmationModal>}
    </div>;
};
export default FilterFilesForm;