import _styled from "styled-components";
import { DisplayedChatMessage } from '../Chat.types';
import { Typography, TypographySize, TypographyWeight } from '~/components/core';
const BatchUserMessageContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.375rem",
  "borderRadius": "0 3px 3px 3px",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(141 118 255 / var(--tw-border-opacity))",
  "backgroundColor": "rgba(37, 34, 69, 0.46)",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "paddingLeft": "1rem",
  "--tw-backdrop-blur": "blur(53.5px)",
  "WebkitBackdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
  "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)"
});
const NameAndMessageContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.375rem"
});
interface BatchUserMessageProps {
  chatMessage: DisplayedChatMessage;
}
const BatchUserMessage = ({
  chatMessage
}: BatchUserMessageProps) => <BatchUserMessageContainer>
    <NameAndMessageContainer>
      <Typography size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
        You
      </Typography>
      <Typography size={TypographySize.SMALL} weight={TypographyWeight.NORMAL}>
        {chatMessage.batch?.prompt}
      </Typography>
    </NameAndMessageContainer>
  </BatchUserMessageContainer>;
export default BatchUserMessage;