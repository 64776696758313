import { ChipContainer, CloseButton, IconWrapper } from './Chip.styles';
import { Icon, Icons, Size, TextIconColor, ToolTip, Typography } from '~/components/core';
import { TypographyWeight, TypographySize, TypographyWrap, TypographyFont } from '~/components/core/Typography/Typography.consts';
interface ChipProps {
  label: string;
  onClick?: () => void;
  onClose?: () => void;
  size?: Size.SMALL | Size.MEDIUM;
  startIcon?: Icon;
  tooltip?: string;
}
export const Chip = ({
  label,
  onClick,
  onClose,
  size = Size.SMALL,
  startIcon,
  tooltip
}: ChipProps) => <ToolTip content={tooltip}>
    <ChipContainer $hasClose={!!onClose} $size={size} onClick={onClick}>
      {startIcon && <IconWrapper>
          <Icons color={TextIconColor.PRIMARY} icon={startIcon} />
        </IconWrapper>}
      <Typography color={TextIconColor.PRIMARY} font={TypographyFont.PARAGRAPH} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM} wrap={TypographyWrap.NO_WRAP}>
        {label}
      </Typography>
      {onClose && <CloseButton onClick={e => {
      e.stopPropagation();
      onClose();
    }}>
          <Icons color={TextIconColor.PRIMARY} icon={Icon.CLOSE} />
        </CloseButton>}
    </ChipContainer>
  </ToolTip>;
export default Chip;