import _styled from "styled-components";
import { useEffect, useState } from 'react';
import SurveyOption from '../SurveyOption';
import { TextField, TextFieldType, Typography, TypographyWeight } from '~/components/core';
const QuestionContainer = _styled.div({
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
});
const QuestionOptions = _styled.div({
  "marginTop": "0px !important",
  "marginBottom": "0.5rem",
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem"
});
type RadioQuestionProps = {
  onChange: (value: string) => void;
  options: string[];
  placeholder: string;
  prompt: string;
  value: string | null;
  otherOptionLabel?: string | undefined;
};
const RadioQuestion = ({
  value,
  onChange,
  options,
  placeholder,
  prompt,
  otherOptionLabel
}: RadioQuestionProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  useEffect(() => {
    setSelectedOption(null);
  }, [options]);
  return <>
      <QuestionContainer>
        <Typography weight={TypographyWeight.SEMI_BOLD}>{prompt}</Typography>
      </QuestionContainer>
      <QuestionOptions>
        {options.map(option => <SurveyOption key={option} checked={option === selectedOption} onChange={() => {
        setSelectedOption(option);
        onChange(option);
      }} value={option} />)}
        {otherOptionLabel && <SurveyOption key={otherOptionLabel} checked={otherOptionLabel === selectedOption} onChange={() => {
        setSelectedOption(otherOptionLabel);
        onChange('');
      }} value={otherOptionLabel} />}
        {selectedOption === otherOptionLabel && <TextField autoFocus onChange={newValue => onChange(newValue)} placeholder={placeholder} type={TextFieldType.DEFAULT} value={value || ''} />}
      </QuestionOptions>
    </>;
};
export default RadioQuestion;