import _styled from "styled-components";
import { Checkbox } from '@mui/material';
import { TextIconColor, Typography, TypographyFont, TypographySize } from '~/components/core';
const SurveyOptionContainer = _styled.div({
  "position": "relative",
  "display": "flex",
  "cursor": "pointer",
  "flexDirection": "row",
  "alignItems": "center",
  "borderRadius": "0.25rem",
  "borderStyle": "none",
  "--tw-bg-opacity": "0.1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
  "padding": "0.5rem"
});
interface SurveyOptionProps {
  checked: boolean;
  onChange: (checked?: boolean) => void;
  value: string;
  onClick?: () => void;
}
const SurveyOption = ({
  checked,
  value,
  onChange,
  onClick
}: SurveyOptionProps) => {
  const handleClick = () => {
    onChange(!checked);
    onClick?.();
  };
  return <SurveyOptionContainer onClick={handleClick}>
      <Checkbox checked={checked} sx={{
      color: {
        "--tw-text-opacity": "1",
        "color": "rgb(68 68 96 / var(--tw-text-opacity))"
      },
      '&.Mui-checked': {
        color: {
          "--tw-text-opacity": "1",
          "color": "rgb(84 254 209 / var(--tw-text-opacity))"
        }
      }
    }} value={value} />
      <Typography color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.SMALL}>
        {value}
      </Typography>
    </SurveyOptionContainer>;
};
export default SurveyOption;