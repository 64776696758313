import _styled from "styled-components";
import { Modal as BaseModal } from '@mui/base/Modal';
import clsx from 'clsx';
import React from 'react';
import { TextIconColor } from '../constants';
import { Icon } from '../Icon';
import { IconButton, IconButtonType } from '../IconButton';
export enum ModalWidth {
  '2XL' = '2xl',
  '3XL' = '3xl',
  FULL = 'full',
  LG = 'lg',
  MD = 'md',
  'NINE_TWENTY' = '920',
  SM = 'sm',
  XL = 'xl',
}
const MODAL_WIDTH_STYLE: Record<ModalWidth, TwStyle> = {
  [ModalWidth.LG]: {
    "maxWidth": "32rem"
  },
  [ModalWidth.MD]: {
    "maxWidth": "28rem"
  },
  [ModalWidth.SM]: {
    "maxWidth": "24rem"
  },
  [ModalWidth.XL]: {
    "maxWidth": "36rem"
  },
  [ModalWidth['2XL']]: {
    "maxWidth": "42rem"
  },
  [ModalWidth['3XL']]: {
    "maxWidth": "48rem"
  },
  [ModalWidth.NINE_TWENTY]: {
    "maxWidth": "920px"
  },
  [ModalWidth.FULL]: {
    "maxWidth": "100%"
  }
};
const StyledModal = _styled(BaseModal)({
  "position": "fixed",
  "inset": "0px",
  "zIndex": "50",
  "margin": "0.25rem",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "overflow": "hidden"
});
const CloseButtonContainer = _styled.div({
  "position": "absolute",
  "right": "0.5rem",
  "top": "0.5rem"
});
const ModalContent = _styled.div<{
  $hasOnClose: boolean;
  $noContentPadding: boolean;
  $width: ModalWidth;
}>(({
  $hasOnClose,
  $noContentPadding,
  $width
}) => [{
  "position": "relative",
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "borderRadius": "0.75rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(21 23 46 / var(--tw-bg-opacity))",
  "--tw-shadow": "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  "--tw-shadow-colored": "0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
}, !$noContentPadding && {
  "padding": "2rem"
}, $hasOnClose && {
  "paddingTop": "2.5rem"
}, MODAL_WIDTH_STYLE[$width]]);

// https://mui.com/base-ui/react-modal/
const Backdrop = React.forwardRef<HTMLDivElement, {
  className: string;
  open?: boolean;
}>((props, ref) => {
  const {
    open,
    className,
    ...other
  } = props;
  return <div ref={ref} className={clsx({
    'MuiBackdrop-open': open
  }, className)}
  // eslint-disable-next-line react/jsx-props-no-spreading
  {...other} />;
});
const StyledBackdrop = _styled(Backdrop)({
  "position": "fixed",
  "inset": "0px",
  "zIndex": "-1",
  "--tw-bg-opacity": "0.6",
  "backgroundColor": "rgb(0 0 0 / var(--tw-bg-opacity))",
  "transitionProperty": "opacity",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
});
type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  noContentPadding?: boolean;
  onClose?: () => void;
  width?: ModalWidth;
};
export const Modal = ({
  open,
  onClose,
  children,
  width = ModalWidth.LG,
  noContentPadding = false
}: ModalProps) => {
  const handleClose = () => {
    if (!onClose) return;
    onClose();
  };
  if (!open) return null;

  // https://mui.com/base-ui/react-modal/
  return <StyledModal aria-modal="true" onClose={handleClose} open={open} slots={{
    backdrop: StyledBackdrop
  }}>
      <ModalContent $hasOnClose={!!onClose} $noContentPadding={noContentPadding} $width={width}>
        {!!onClose && <CloseButtonContainer>
            <IconButton color={TextIconColor.PRIMARY} icon={Icon.CLOSE} onClick={handleClose} type={IconButtonType.SIMPLE} />
          </CloseButtonContainer>}
        {children}
      </ModalContent>
    </StyledModal>;
};
export default Modal;