import { FileType, isImageFileType } from '@kindo/universal';
import Image from 'next/image';
import React from 'react';
import { getContentProxyFetchUrl } from '~/constants';
type ImageFilePreviewProps = {
  fileId?: string | undefined;
  fileType?: FileType | undefined;
};
const ImageFilePreview: React.FC<ImageFilePreviewProps> = ({
  fileType,
  fileId
}) => {
  if (!fileId || !isImageFileType(fileType)) {
    return <span>Cannot preview file</span>;
  }
  const fileUrl = getContentProxyFetchUrl(fileId);
  return <Image alt="Preview" height={200} loader={() => fileUrl} src={fileUrl} width={200} />;
};
export default React.memo(ImageFilePreview);