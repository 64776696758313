import _styled from "styled-components";
import { Size } from '../constants';
import { ToggleSize, TOGGLE_SIZE_STYLES, TOGGLE_INNER_BOX_STYLES, TOGGLE_BORDER_RADIUS_STYLES } from './Toggle.consts';
export const ToggleContainer = _styled.label({
  "position": "relative",
  "display": "inline-flex",
  "cursor": "pointer",
  "alignItems": "center"
});
export const StyledInput = _styled.input({
  "position": "absolute",
  "height": "0px",
  "width": "0px",
  "opacity": "0"
});
export const StyledToggleDisplay = _styled.div<{
  $checked: boolean;
  $disabled: boolean;
  $loading: boolean;
  $size: ToggleSize;
}>(({
  $checked,
  $disabled,
  $loading,
  $size
}) => [
// Base styles
{
  "position": "relative",
  "display": "flex",
  "alignItems": "center",
  "overflow": "hidden",
  "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "100ms"
}, {
  "borderWidth": "1px",
  "backgroundColor": "transparent"
}, TOGGLE_SIZE_STYLES[$size], TOGGLE_BORDER_RADIUS_STYLES[$size], {
  "borderColor": "rgb(255 255 255 / 0.1)",
  "&:hover": {
    "borderColor": "rgb(255 255 255 / 0.5)"
  }
}, $checked && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(84 254 209 / var(--tw-border-opacity))",
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(255 255 255 / var(--tw-border-opacity))"
  }
},
// Loading styles
$loading && {
  "&::after": {
    "content": "var(--tw-content)",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center"
  }
},
// Disabled styles
$disabled && {
  "cursor": "not-allowed",
  "borderWidth": "1px",
  "borderStyle": "dashed",
  "borderColor": "rgb(255 255 255 / 0.1)",
  "&:hover": {
    "borderColor": "rgb(255 255 255 / 0.1)"
  }
}]);
export const StyledInnerBox = _styled.div<{
  $checked: boolean;
  $disabled: boolean;
  $size: ToggleSize;
}>(({
  $checked,
  $disabled,
  $size
}) => [
// Base styles
{
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center"
}, {
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "300ms"
}, TOGGLE_INNER_BOX_STYLES[$size],
// Off/Active
!$checked && [{
  "backgroundColor": "rgb(255 255 255 / 0.1)"
}], $checked && [{
  [Size.SMALL]: {
    "--tw-translate-x": "27px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  [Size.MEDIUM]: {
    "--tw-translate-x": "33px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  [Size.LARGE]: {
    "--tw-translate-x": "33px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  }
}[$size], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(84 254 209 / var(--tw-bg-opacity))"
}, {
  "--tw-shadow": "0px 0px 20px 4px rgb(84 254 209)",
  "--tw-shadow-colored": "0px 0px 20px 4px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "transitionDuration": "400ms"
}, $disabled && {
  "backgroundColor": "rgb(84 254 209 / 0.2)",
  "--tw-shadow": "0px 0px 20px 4px rgb(84 254 209 / 0.2)",
  "--tw-shadow-colored": "0px 0px 20px 4px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
}]]);