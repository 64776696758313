import _styled from "styled-components";
import { useRouter } from 'next/router';
import { Background } from '../Background';
import { Button, ButtonType, TextIconColor, Typography } from '../core';
import { TypographySize } from '../core/Typography';
const ErrorScreenContainer = _styled.div({
  "display": "flex",
  "height": "100vh",
  "width": "100vw",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "1.5rem",
  "padding": "2.5rem",
  "textAlign": "center"
});
const ErrorScreenTextContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.5rem"
});

// https://github.com/bvaughn/react-error-boundary
const ErrorScreen: React.FC = () => {
  const router = useRouter();
  return <ErrorScreenContainer>
      <Background />
      <ErrorScreenTextContainer>
        <Typography color={TextIconColor.PRIMARY} size={TypographySize.H1}>
          Uh Oh
        </Typography>
        <div>
          <Typography color={TextIconColor.PRIMARY} size={TypographySize.H2}>
            Something went wrong.
          </Typography>
        </div>
      </ErrorScreenTextContainer>
      <Button label="Return to home screen" onClick={() => router.replace('/')} type={ButtonType.SOLID} />
    </ErrorScreenContainer>;
};
export default ErrorScreen;