import _styled from "styled-components";
import { useRouter } from 'next/router';
import React from 'react';
import TextLogoSvg from '../assets/logo-text.svg';
import { withLink } from '../core/hocs';
import { ToggleNavigator } from '../ToggleNavigator';
import { NavButton } from './NavButton';
import { ProfileButton } from './ProfileButton';
import { Icon } from '~/components/core';
import { SETTINGS_ROUTE, SUPPORT_ROUTE, ROOT_ROUTE, WORKFLOW_BUILDER_ROUTE, LIBRARY_ROUTE } from '~/constants';
const NavContainer = _styled.nav({
  "position": "relative",
  "display": "flex",
  "height": "3.5rem",
  "width": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "borderBottomWidth": "1px",
  "borderColor": "rgba(103, 97, 250, 0.37)",
  "paddingLeft": "0.75rem",
  "paddingRight": "0.75rem",
  "@media (min-width: 640px)": {
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem"
  }
});
const CenterNavGroup = _styled.div({
  "position": "absolute",
  "left": "50%",
  "top": "50%",
  "width": "auto",
  "minWidth": "0px",
  "--tw-translate-x": "-50%",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "--tw-translate-y": "-50%"
});
const NavButtonGroup = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "0.125rem",
  "@media (min-width: 640px)": {
    "gap": "0.25rem"
  },
  "@media (min-width: 768px)": {
    "gap": "0.5rem"
  }
});
const LogoContainer = _styled.span({
  "width": "8rem",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
});
enum NavToggle {
  AGENT = 'AGENT',
  CHAT = 'CHAT',
  LIBRARY = 'LIBRARY',
}
const NAV_TOGGLE_DISPLAY_NAMES: Record<NavToggle, string> = {
  [NavToggle.CHAT]: 'Chat',
  [NavToggle.AGENT]: 'Agent',
  [NavToggle.LIBRARY]: 'Library'
};
const NAV_TOGGLE_ROUTES: Record<NavToggle, string> = {
  [NavToggle.CHAT]: ROOT_ROUTE,
  [NavToggle.AGENT]: WORKFLOW_BUILDER_ROUTE,
  [NavToggle.LIBRARY]: LIBRARY_ROUTE
};
const Nav = () => {
  const router = useRouter();
  return <NavContainer>
      <NavButtonGroup>
        {/* New Chat (Home) */}
        {withLink(<LogoContainer>
            <TextLogoSvg />
          </LogoContainer>, {
        href: ROOT_ROUTE,
        fullSize: true
      })}
      </NavButtonGroup>
      <NavButtonGroup>
        <CenterNavGroup>
          <ToggleNavigator currentPath={router.pathname} toggleOptions={[{
          label: NAV_TOGGLE_DISPLAY_NAMES[NavToggle.CHAT],
          value: NavToggle.CHAT,
          icon: Icon.CHAT,
          route: NAV_TOGGLE_ROUTES[NavToggle.CHAT]
        }, {
          label: NAV_TOGGLE_DISPLAY_NAMES[NavToggle.AGENT],
          value: NavToggle.AGENT,
          icon: Icon.BOLT,
          route: NAV_TOGGLE_ROUTES[NavToggle.AGENT]
        }, {
          label: NAV_TOGGLE_DISPLAY_NAMES[NavToggle.LIBRARY],
          value: NavToggle.LIBRARY,
          icon: Icon.GRID,
          route: NAV_TOGGLE_ROUTES[NavToggle.LIBRARY]
        }]} />
        </CenterNavGroup>
      </NavButtonGroup>
      <NavButtonGroup>
        {/* Settings */}
        <NavButton data-id="nav_settings" icon={Icon.SETTINGS} isCurrentRoute={router.pathname.includes(SETTINGS_ROUTE)} route={SETTINGS_ROUTE} />
        {/* Support */}
        <NavButton data-id="nav_support" icon={Icon.SUPPORT} isCurrentRoute={router.pathname.includes(SUPPORT_ROUTE)} route={SUPPORT_ROUTE} />
        {/* Profile */}
        <ProfileButton />
      </NavButtonGroup>
    </NavContainer>;
};
export default React.memo(Nav);