import _styled from "styled-components";
import { Button, ButtonType, TextIconColor, Icon, Icons, Typography, TypographySize } from '~/components/core';
import { Content } from '~/types';
const StaticContentItems = _styled.div({
  "display": "grid",
  "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
});
const ContentIconAndName = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "gap": "0.25rem"
});
const ButtonsContainer = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "flex-end",
  "gap": "0.5rem"
});
interface ShareStaticContentConfirmationProps {
  onClose: () => void;
  onConfirm: () => void;
  staticContent: Pick<Content, 'fileName'>[];
}
const ShareStaticContentConfirmation: React.FC<ShareStaticContentConfirmationProps> = ({
  staticContent,
  onClose,
  onConfirm
}) => <>
    <Typography color={TextIconColor.PRIMARY} size={TypographySize.H3}>
      Share agent with included files
    </Typography>
    <Typography color={TextIconColor.SECONDARY}>
      The agent you are about to share includes the following files:
    </Typography>
    <StaticContentItems>
      {staticContent.map(content => <ContentIconAndName key={content.fileName}>
          <Icons color={TextIconColor.PRIMARY} icon={Icon.FILE} />
          <Typography color={TextIconColor.PRIMARY}>
            {content.fileName}
          </Typography>
        </ContentIconAndName>)}
    </StaticContentItems>
    <Typography color={TextIconColor.SECONDARY}>
      It is possible that the contents of these files could be exposed to a
      runner of this agent.
    </Typography>
    <Typography color={TextIconColor.SECONDARY}>
      Would you like to continue sharing this agent?
    </Typography>
    <ButtonsContainer>
      <Button label="Cancel" onClick={onClose} type={ButtonType.OUTLINED} />
      <Button label="Share" onClick={onConfirm} type={ButtonType.SOLID} />
    </ButtonsContainer>
  </>;
export default ShareStaticContentConfirmation;