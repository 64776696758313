import { useState } from 'react';
import { ButtonType, Size, Icon, Button, TextIconColor } from '~/components/core';
import { nextTrpc } from '~/trpc';
const RunCodeButton: React.FC<{
  chatMessageId: string;
  codeIndex: number;
  disabled: boolean;
  textIconColor?: TextIconColor;
}> = ({
  chatMessageId,
  codeIndex,
  disabled,
  textIconColor
}) => {
  const [isRunning, setIsRunning] = useState(false);
  const executeCodeMutation = nextTrpc.chatMessage.executeCode.useMutation({
    onMutate: () => {
      setIsRunning(true);
    },
    onSettled: () => {
      setIsRunning(false);
    }
  });
  return <Button disabled={disabled || isRunning} endIcon={Icon.PLAY} label="Run code" onClick={() => {
    executeCodeMutation.mutateAsync({
      messageId: chatMessageId,
      codeIndex
    });
  }} size={Size.SMALL} textIconColor={textIconColor} type={ButtonType.TEXT} />;
};
export default RunCodeButton;