// These values map to the placement prop of the MUI Popper component
// https://mui.com/material-ui/react-popper/?srsltid=AfmBOopafa9Kz5_WL3SAm7ZHPB0T8kUBjgnG5zyngtOFUpGZj6Bt9NKY#tailwind-PositionedPopper.tsx

export enum DropdownPlacement {
  BOTTOM = 'bottom',
  BOTTOM_END = 'bottom-end',
  BOTTOM_START = 'bottom-start',
  LEFT = 'left',
  LEFT_END = 'left-end',
  LEFT_START = 'left-start',
  RIGHT = 'right',
  RIGHT_END = 'right-end',
  RIGHT_START = 'right-start',
  TOP = 'top',
  TOP_END = 'top-end',
  TOP_START = 'top-start',
}