/* eslint-disable no-param-reassign */
import { ContentMode } from '@kindo/universal';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface ChatState {
  contentMode: ContentMode;
}

const initialState: ChatState = {
  contentMode: ContentMode.ALL
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setContentMode: (
      state,
      action: PayloadAction<{ contentMode: ContentMode }>
    ) => {
      state.contentMode = action.payload.contentMode;
    }
  }
});

export const chatActions = chatSlice.actions;
