import _styled from "styled-components";
import { useState } from 'react';
import { Pagination } from '~/components/Chat/AddFileModal/Pagination';
import { TextIconColor, Table, TableColumn, TableRow } from '~/components/core';
import { TypographyWrap } from '~/components/core/Typography';
const ROWS_PER_PAGE = 5;
const CsvTableContainer = _styled.div({
  "position": "relative",
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "overflow": "auto",
  "borderRadius": "0.375rem",
  "--tw-drop-shadow": "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
  "filter": "var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)"
});
const CsvDataContainer = _styled.div({
  "flexGrow": "1",
  "overflow": "auto",
  "paddingBottom": "0.5rem"
});
const PaginationContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "space-between",
  "borderTopWidth": "1px",
  "borderStyle": "dashed",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))",
  "padding": "0.5rem"
});

// Object that maps column name to cell value, e.g. { 'column1': 'value1', 'column2': 'value2' }
export type CsvRow = Record<string, string>;
interface CsvTableProps {
  rows: CsvRow[];
  columnsWithTooltip?: string[];
}
const CsvTable: React.FC<CsvTableProps> = ({
  rows,
  columnsWithTooltip = []
}: CsvTableProps) => {
  const [page, setPage] = useState(0);
  const columnNames = rows[0] ? Object.keys(rows[0]) : [];
  const columns = [...columnNames.map((col): TableColumn => ({
    title: col,
    width: 200,
    wrap: TypographyWrap.NO_WRAP,
    tooltipEnabled: columnsWithTooltip.includes(col)
  }))];
  const csvRows: TableRow[] = rows.map((row, index) => ({
    key: `row-${index + 1}`,
    textColor: TextIconColor.SECONDARY,
    cells: Object.values(row),
    height: {
      "height": "2rem"
    }
  }));
  const start = page * ROWS_PER_PAGE;
  const pageRows = csvRows.slice(start, start + ROWS_PER_PAGE);
  const emptyRows: TableRow[] = Array.from({
    length: ROWS_PER_PAGE - pageRows.length
  }, (_, index) => ({
    key: `empty-${start + pageRows.length + index}`,
    textColor: TextIconColor.SECONDARY,
    cells: Array(columns.length).fill('')
  }));
  const displayedRows = [...pageRows, ...emptyRows];
  return <CsvTableContainer>
      <CsvDataContainer>
        <Table<string, string> columns={columns} isCsv noRowsText="No data found" rows={displayedRows} showColumnHeaderBorder={false} />
      </CsvDataContainer>
      <PaginationContainer>
        <Pagination initialPage={page} onPageActive={() => {}} onPageChange={setPage} totalPages={Math.ceil(csvRows.length / ROWS_PER_PAGE)} />
      </PaginationContainer>
    </CsvTableContainer>;
};
export default CsvTable;