// TODO: Figure out long-term solution for this eslint rule and RTK
/* eslint-disable no-param-reassign */
import { OrgPaymentTier, OrgPaymentTierConfig } from '@kindo/universal';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface UserOrgData {
  id: string;
  name: string | null;
  paymentTier: OrgPaymentTier;
  paymentTierConfig: Pick<
    OrgPaymentTierConfig,
    'accessSecuritySettings' | 'maxMembers' | 'maxWorkflows'
  >;
  workosOrgId: string | null;
}

interface UserState {
  org: UserOrgData | null;
  userEmail: string | null;
  userId: string | null;
}

const initialState: UserState = {
  userId: null,
  userEmail: null,
  org: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (
      state,
      action: PayloadAction<{
        org: UserOrgData;
        userEmail: string;
        userId: string;
      }>
    ) => {
      state.userId = action.payload.userId;
      state.userEmail = action.payload.userEmail;
      state.org = action.payload.org;
    },
    updateOrgName: (state, action: PayloadAction<{ orgName: string }>) => {
      if (state.org) {
        state.org.name = action.payload.orgName;
      }
    }
  }
});

export const userActions = userSlice.actions;
