import _styled from "styled-components";
import { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { groupWorkstationHistoryItemsByDate, WORKSTATION_HISTORY_DATE_GROUPS } from '../../WorkstationHistorySidebar.utils';
import { HistoryContainer, GroupContainer, GroupTitle, HistoryItem } from '../HistorySidebarComponentUtils';
import { Select } from '~/components/core';
import { TextIconColor } from '~/components/core/constants';
import { Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core/Typography';
import { AgentRunType, CHAT_ID_QUERY_PARAM, getWorkstationRoute, UNTITLED_WORKFLOW_NAME } from '~/constants';
import { useURLParams } from '~/hooks/useUrlParams';
import { nextTrpc } from '~/trpc';
interface AgentRunHistoryProps {
  onItemClick: (route: string) => Promise<void>;
}
const SelectContainer = _styled.div({
  "marginTop": "0.75rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
const AgentBadge = _styled.span({
  "display": "inline-flex",
  "alignItems": "center",
  "borderRadius": "0.125rem",
  "backgroundColor": "rgb(255 255 255 / 0.1)",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem"
});
const AGENT_TYPE_TO_ABBREVIATION: Record<AgentRunType, string> = {
  [AgentRunType.TRIGGER]: 'TRIG',
  [AgentRunType.WORKFLOW]: 'WKFL',
  [AgentRunType.CHATBOT]: 'CHTB'
};
const AGENT_TYPE_TO_LABEL: Record<AgentRunType, string> = {
  [AgentRunType.TRIGGER]: 'Trigger',
  [AgentRunType.WORKFLOW]: 'Workflow',
  [AgentRunType.CHATBOT]: 'Chatbot'
};
const AgentRunHistory = ({
  onItemClick
}: AgentRunHistoryProps) => {
  // URL Params
  const params = useURLParams<{
    [CHAT_ID_QUERY_PARAM]?: string;
  }>();
  const {
    [CHAT_ID_QUERY_PARAM]: urlChatId
  } = params;
  const [agentRunType, setAgentRunType] = useState<AgentRunType | null>(null);

  // Queries
  const {
    data
  } = nextTrpc.chats.list.useQuery();
  const agentRunHistory = data?.items.filter(chat => !!chat.workflowId).map(item => ({
    id: item.id,
    creatorName: item?.creator?.name || '',
    title: item.title || UNTITLED_WORKFLOW_NAME,
    timestamp: item?.updatedAt || '',
    workflowId: item.workflowId!,
    agentRunType: item.workflowTriggerId ? AgentRunType.TRIGGER : AgentRunType.WORKFLOW
  })) || [];
  const groupedAgents = groupWorkstationHistoryItemsByDate(agentRunHistory);
  const handleMobileClick = (route: string) => {
    void onItemClick(route);
  };
  return <HistoryContainer>
      <SelectContainer>
        <Select onChange={setAgentRunType} options={[{
        label: 'All',
        value: null
      }, ...Object.values(AgentRunType).map(type => ({
        label: AGENT_TYPE_TO_LABEL[type],
        value: type
      }))]} placeholderLabel="Filter by agent type" prefix="Type: " value={agentRunType} />
      </SelectContainer>
      {WORKSTATION_HISTORY_DATE_GROUPS.map(({
      key,
      title
    }) => {
      const chats = groupedAgents[key];
      return chats && chats.length > 0 ? <GroupContainer key={key}>
            <GroupTitle title={title} />
            {chats.map(chat => {
          const route = getWorkstationRoute({
            chatId: chat.id,
            workflowId: chat.workflowId || undefined
          });
          return <HistoryItem key={chat.id}>
                  <AgentBadge>
                    <Typography font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM}>
                      {AGENT_TYPE_TO_ABBREVIATION[chat.agentRunType]}
                    </Typography>
                  </AgentBadge>
                  <Typography color={urlChatId === chat.id ? TextIconColor.PRIMARY : TextIconColor.SECONDARY} href={isMobileOnly ? '' : route} onClick={() => {
              if (isMobileOnly) {
                handleMobileClick(route);
              }
            }}>
                    {chat.title}
                  </Typography>
                </HistoryItem>;
        })}
          </GroupContainer> : null;
    })}
    </HistoryContainer>;
};
export default AgentRunHistory;