import {
  SUPPORTED_LLMS,
  SupportedLlm,
  Llm,
  Tool,
  CHAT_AGENT_TOOLS
} from '@kindo/universal';

// Prepend with 'kindo:' to avoid collisions with other apps
export enum LocalStorageKey {
  CHAT_LLM = 'kindo:chat-modal', // TODO: Rename to "model"
  ENABLED_TOOLS = 'kindo:enabled-tools',
  HISTORY_MENU_ACTIVE_TAB = 'kindo:history-menu-active-tab'
}

export enum WorkstationHistorySidebarActiveTab {
  AGENTS = 'AGENTS',
  CHATS = 'CHATS',
  CLOSED = ''
}

// Defines key/value pair of LocalStorage items
export interface LocalStorageItems {
  [LocalStorageKey.CHAT_LLM]: SupportedLlm;
  [LocalStorageKey.ENABLED_TOOLS]: string;
  [LocalStorageKey.HISTORY_MENU_ACTIVE_TAB]: WorkstationHistorySidebarActiveTab;
}

export const DEFAULT_LOCAL_STORAGE_VALUES: LocalStorageItems = {
  [LocalStorageKey.CHAT_LLM]: Llm.CLAUDE_3_5_SONNET,
  [LocalStorageKey.ENABLED_TOOLS]: JSON.stringify([]),
  [LocalStorageKey.HISTORY_MENU_ACTIVE_TAB]:
    WorkstationHistorySidebarActiveTab.CLOSED
};

export const DEFAULT_LOCAL_STORAGE_VALIDATORS: {
  [K in LocalStorageKey]: (value: any) => value is LocalStorageItems[K];
} = {
  [LocalStorageKey.CHAT_LLM]: (value): value is SupportedLlm =>
    Object.values(SUPPORTED_LLMS).includes(value),
  [LocalStorageKey.ENABLED_TOOLS]: (value): value is string => {
    const enabledTools: Tool[] = JSON.parse(value);
    return (
      Array.isArray(enabledTools) &&
      (enabledTools.length === 0 ||
        enabledTools.every((tool: Tool) => CHAT_AGENT_TOOLS.includes(tool)))
    );
  },
  [LocalStorageKey.HISTORY_MENU_ACTIVE_TAB]: (
    value
  ): value is WorkstationHistorySidebarActiveTab =>
    Object.values(WorkstationHistorySidebarActiveTab).includes(value)
};
