import _styled from "styled-components";
import { getFileTypeFromMimeType, isImageFileType, ChatMessageState, filterExists } from '@kindo/universal';
import { useState } from 'react';
import { ChatMessageContentReference, UserChatMessage, isReadyUserChatMessage } from '../Chat.types';
import { UserChatMessageInput } from './UserChatMessageInput';
import { UserChatMessageInputValue, UserInputValueType } from './UserChatMessageInput/UserChatMessageInput';
import { Button, ButtonType, TextIconColor, Icon, IconButton, IconButtonType, Size, ToolTip, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { TypographyWrap } from '~/components/core/Typography';
import { ImageFilePreview } from '~/components/shared';
import { ContentReference } from '~/components/shared/ContentReference';
import { ToastType, useAppSelector, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
const UserChatMessageContainer = _styled.div<{
  $state: ChatMessageState;
}>(({
  $state
}) => [{
  "position": "relative",
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.375rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "paddingLeft": "1rem"
}, {
  "borderRadius": "0 3px 3px 3px",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(141 118 255 / var(--tw-border-opacity))"
}, {
  "backgroundColor": "rgb(9 9 9 / 0.5)",
  "--tw-backdrop-blur": "blur(53.5px)",
  "WebkitBackdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
  "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)"
}, $state === ChatMessageState.QUEUED && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(209 213 219 / var(--tw-border-opacity))"
}, $state === ChatMessageState.READY && {
  "borderLeftWidth": "4px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(253 224 71 / var(--tw-border-opacity))"
}]);
const ReferencedFilesContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "flexWrap": "wrap",
  "gap": "0.5rem"
});
const ReferencedImagesContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "flex-end"
});
const NonImageFiles = _styled.div({
  "display": "flex",
  "flexDirection": "row"
});
const NameAndMessageContainer = _styled.div({
  "position": "relative",
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.375rem"
});
const DisplayNameContainer = _styled.div({
  "display": "flex",
  "alignItems": "center"
});
const LockIconContainer = _styled.div({
  "position": "absolute",
  "right": "0.25rem",
  "top": "-12px"
});
const InputsAndSendButtonContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem"
});
const SendButtonContainer = _styled.div({
  "display": "flex",
  "justifyContent": "flex-end"
});
const InputsContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem"
});
interface UserChatMessageProps {
  chatMessage: UserChatMessage;
  refetchChatMessages: () => void;
}
const UserChatMessageComponent: React.FC<UserChatMessageProps> = ({
  chatMessage,
  refetchChatMessages
}) => {
  const {
    state,
    displayName,
    referencedContent,
    message,
    transformedMessage,
    workflowInputValues: existingWorkflowInputValues
  } = chatMessage;

  // State
  // Map of inputId to UserChatMessageInputValue
  const [workflowStepInputValueMap, setWorkflowStepInputValueMap] = useState<Record<string, UserChatMessageInputValue | undefined>>({});

  // State to show the original, non-redacted message
  const [showOriginalMessage, setShowOriginalMessage] = useState(false);

  // Custom hooks
  const {
    enqueueToast
  } = useToast();

  // Redux
  const {
    userId
  } = useAppSelector(({
    user
  }) => user);

  // Mutations
  const sendReadyChatMessageMutation = nextTrpc.chatMessage.sendReadyChatMessage.useMutation({
    onSuccess: () => refetchChatMessages(),
    onError: err => {
      console.error('An error occurred while sending the message.', err);
      enqueueToast({
        message: 'An error occurred while sending the message.',
        type: ToastType.ERROR
      });
    }
  });
  const workflowStepInputValues = Object.values(workflowStepInputValueMap).filter(filterExists);
  const inputsWithoutValues = isReadyUserChatMessage(chatMessage) ? chatMessage.userInputs.filter(input => !existingWorkflowInputValues?.find(workflowInput => workflowInput.inputId === input.id)) : [];
  const getTextColor = (): TextIconColor => {
    switch (true) {
      case state === ChatMessageState.QUEUED:
        return TextIconColor.SECONDARY;
      case !!displayName:
        return TextIconColor.PRIMARY;
      default:
        return TextIconColor.PRIMARY;
    }
  };
  const getTextSize = (): TypographySize => {
    switch (true) {
      case state === ChatMessageState.READY:
        return TypographySize.LARGE;
      case !!displayName:
        return TypographySize.MEDIUM;
      default:
        return TypographySize.SMALL;
    }
  };
  const getTextWeight = (): TypographyWeight => {
    switch (true) {
      case state === ChatMessageState.READY:
        return TypographyWeight.SEMI_BOLD;
      default:
        return TypographyWeight.MEDIUM;
    }
  };
  const handleSendMessage = () => sendReadyChatMessageMutation.mutate({
    messageId: chatMessage.id,
    userInputs: workflowStepInputValues.map(userInput => ({
      inputId: userInput.inputId,
      payload: userInput.type === UserInputValueType.TEXT ? {
        text: userInput.value
      } : {
        contentId: userInput.content.id
      }
    }))
  });
  const setInputValue = (value: UserChatMessageInputValue | undefined, inputId: string) => {
    setWorkflowStepInputValueMap(prev => ({
      ...prev,
      [inputId]: value
    }));
  };
  const renderContentReference = (content: ChatMessageContentReference) => {
    const fileType = getFileTypeFromMimeType(content.mimeType);

    // Images
    if (fileType && isImageFileType(fileType)) {
      return <ReferencedImagesContainer>
          <ImageFilePreview fileId={content.contentId} fileType={fileType} />
          <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL}>
            {content.fileName}
          </Typography>
        </ReferencedImagesContainer>;
    }

    // All other content types
    return <NonImageFiles>
        <ContentReference key={content.referenceId} canDownload={content.creatorId === userId} contentId={content.contentId} fileName={content.fileName} source={content.source} />
      </NonImageFiles>;
  };
  return <UserChatMessageContainer $state={state}>
      {!!referencedContent?.length && <ReferencedFilesContainer>
          {referencedContent.map(renderContentReference)}
        </ReferencedFilesContainer>}
      <NameAndMessageContainer>
        <DisplayNameContainer>
          <Typography color={getTextColor()} size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
            {displayName ? 'Agent' : 'You'}
          </Typography>
          {transformedMessage && <ToolTip content="Your organization has enabled DLP controls. Redacted data will not be sent. Click to reveal original message.">
              <LockIconContainer>
                <IconButton color={TextIconColor.PRIMARY} icon={Icon.LOCK} onClick={() => setShowOriginalMessage(prev => !prev)} size={Size.MEDIUM} type={showOriginalMessage ? IconButtonType.SOLID : IconButtonType.SIMPLE} />
              </LockIconContainer>
            </ToolTip>}
        </DisplayNameContainer>
        <Typography color={getTextColor()} size={getTextSize()} weight={getTextWeight()} wrap={TypographyWrap.PRE_WRAP}>
          {displayName || (showOriginalMessage ? message : transformedMessage || message)}
        </Typography>
      </NameAndMessageContainer>
      {/* Ready message inputs and send button */}
      {isReadyUserChatMessage(chatMessage) && <InputsAndSendButtonContainer>
          {/* Inputs */}
          {inputsWithoutValues.length > 0 && <InputsContainer>
              <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
                Please provide values for the following input variables:
              </Typography>
              {inputsWithoutValues.map(newInput => <UserChatMessageInput key={newInput.id} input={newInput} inputValue={workflowStepInputValueMap[newInput.id]} model={chatMessage.model} setInputValue={setInputValue} />)}
            </InputsContainer>}
          {/* Send button */}
          <SendButtonContainer>
            <Button disabled={workflowStepInputValues.length !== inputsWithoutValues.length} label="Send" loading={sendReadyChatMessageMutation.isLoading} onClick={handleSendMessage} type={ButtonType.SOLID} />
          </SendButtonContainer>
        </InputsAndSendButtonContainer>}
    </UserChatMessageContainer>;
};
export default UserChatMessageComponent;