import { Size } from '../constants';
export type ToggleSize = Size.SMALL | Size.MEDIUM | Size.LARGE;
export enum ToggleState {
  ACTIVE = 'ACTIVE',
  OFF = 'OFF',
}
export const TOGGLE_SIZE_STYLES: Record<ToggleSize, TwStyle> = {
  [Size.SMALL]: {
    "height": "22px",
    "width": "74px",
    "padding": "0.125rem"
  },
  [Size.MEDIUM]: {
    "height": "1.75rem",
    "width": "5rem",
    "padding": "0.125rem"
  },
  [Size.LARGE]: {
    "height": "2.5rem",
    "width": "5rem",
    "padding": "0.125rem"
  }
};
export const TOGGLE_INNER_BOX_STYLES: Record<ToggleSize, TwStyle> = {
  [Size.SMALL]: {
    "height": "1rem",
    "width": "41px",
    "borderRadius": "0.125rem"
  },
  [Size.MEDIUM]: {
    "height": "22px",
    "width": "41px",
    "borderRadius": "0.25rem"
  },
  [Size.LARGE]: {
    "height": "34px",
    "width": "41px",
    "borderRadius": "0.25rem"
  }
};
export const TOGGLE_BORDER_RADIUS_STYLES: Record<ToggleSize, TwStyle> = {
  [Size.SMALL]: {
    "borderRadius": "0.25rem"
  },
  [Size.MEDIUM]: {
    "borderRadius": "0.375rem"
  },
  [Size.LARGE]: {
    "borderRadius": "0.375rem"
  }
};