import { isMobileOnly } from 'react-device-detect';
import { groupWorkstationHistoryItemsByDate, WORKSTATION_HISTORY_DATE_GROUPS } from '../../WorkstationHistorySidebar.utils';
import { HistoryContainer, GroupContainer, GroupTitle, HistoryItem } from '../HistorySidebarComponentUtils';
import { TextIconColor } from '~/components/core/constants';
import { Typography, TypographyWrap } from '~/components/core/Typography';
import { CHAT_ID_QUERY_PARAM, getWorkstationRoute, UNTITLED_CHAT_NAME } from '~/constants';
import { useURLParams } from '~/hooks/useUrlParams';
import { nextTrpc } from '~/trpc';
interface ChatHistoryProps {
  onItemClick: (route: string) => Promise<void>;
}
const ChatHistory = ({
  onItemClick
}: ChatHistoryProps) => {
  // URL Params
  const params = useURLParams<{
    [CHAT_ID_QUERY_PARAM]?: string;
  }>();
  const {
    [CHAT_ID_QUERY_PARAM]: urlChatId
  } = params;
  const {
    data
  } = nextTrpc.chats.list.useQuery();
  const chatHistory = data?.items.map(item => ({
    id: item.id,
    creatorName: item?.creator?.name || '',
    title: item.title || UNTITLED_CHAT_NAME,
    timestamp: item?.updatedAt || '',
    workflowId: item.workflowId
  })) || [];
  const groupedChats = groupWorkstationHistoryItemsByDate(chatHistory);
  const handleMobileClick = (route: string) => {
    void onItemClick(route);
  };
  return <HistoryContainer>
      {WORKSTATION_HISTORY_DATE_GROUPS.map(({
      key,
      title
    }) => {
      const chats = groupedChats[key];
      return chats && chats.length > 0 ? <GroupContainer key={key}>
            <GroupTitle title={title} />
            {chats.map(chat => {
          const route = getWorkstationRoute({
            chatId: chat.id,
            workflowId: chat.workflowId || undefined
          });
          return <HistoryItem key={chat.id}>
                  <Typography color={urlChatId === chat.id ? TextIconColor.PRIMARY : TextIconColor.SECONDARY} href={isMobileOnly ? '' : route} onClick={() => {
              if (isMobileOnly) {
                handleMobileClick(route);
              }
            }} wrap={TypographyWrap.NO_WRAP}>
                    {chat.title}
                  </Typography>
                </HistoryItem>;
        })}
          </GroupContainer> : null;
    })}
    </HistoryContainer>;
};
export default ChatHistory;