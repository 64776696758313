export type BackgroundVariant = 'default' | 'builder';
interface BackgroundProps {
  variant?: BackgroundVariant;
}
const backgroundGradients = {
  default: {
    gradient: `radial-gradient(65.73% 38.37% at 85% 15%, rgba(48, 28, 157, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%),
    radial-gradient(64.25% 63.44% at 15% 85%, rgba(48, 28, 157, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%),
    linear-gradient(0deg, #070D19 0%, #070D19 100%),
    radial-gradient(37.01% 36.22% at 0% 0%, rgba(32, 21, 107, 0.55) 51.64%, transparent 100%),
    #131313`
  },
  builder: {
    gradient: `radial-gradient(65.73% 38.37% at 15% 15%, rgba(48, 28, 157, 0.35) 0%, rgba(0, 0, 0, 0.00) 100%),
    radial-gradient(64.25% 63.44% at 85% 85%, rgba(48, 28, 157, 0.25) 0%, rgba(0, 0, 0, 0.00) 100%),
    linear-gradient(0deg, #070D19 0%, #070D19 100%),
    radial-gradient(100% 100% at 30% 100%, rgba(32, 21, 107, 0.75) 30%, transparent 100%),
    #131313`
  }
};
const Background = ({
  variant = 'default'
}: BackgroundProps) => <div css={{
  "position": "absolute",
  "left": "0px",
  "top": "0px",
  "zIndex": "-40",
  "height": "100vh",
  "width": "100vw",
  "overflow": "hidden"
}}>
    {/* Default gradient (always visible) */}
    <div css={{
    "position": "absolute",
    "height": "100%",
    "width": "100%"
  }} style={{
    background: backgroundGradients.default.gradient
  }} />
    {/* Builder gradient (animates in/out) */}
    <div className={`absolute h-full w-full ${variant === 'builder' ? 'animate-fade-in' : 'animate-fade-out'}`} style={{
    background: backgroundGradients.builder.gradient
  }} />
  </div>;
export default Background;