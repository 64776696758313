import { css as _css } from "styled-components";
import _styled from "styled-components";
import { ButtonSize, Size } from '../constants';
import { TypographySize } from '../Typography/Typography.consts';
import { ButtonType } from './Button.consts';

/** Types Styles */

const BUTTON_TYPE_STYLES: Record<ButtonType, TwStyle> = {
  [ButtonType.OUTLINED]: {
    "borderWidth": "1px",
    "borderColor": "rgb(255 255 255 / 0.2)",
    "backgroundColor": "transparent",
    "&:enabled:hover": {
      "borderColor": "rgb(255 255 255 / 0.5)"
    }
  },
  [ButtonType.OUTLINED_COLOR]: {
    "borderWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgb(32 0 200 / var(--tw-border-opacity))",
    "backgroundColor": "transparent",
    "&:enabled:hover": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(53 17 245 / var(--tw-border-opacity))",
      "--tw-shadow": "0px -1px 46.1px 0px #3C00FF",
      "--tw-shadow-colored": "0px -1px 46.1px 0px var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    }
  },
  [ButtonType.SOLID]: {
    "backgroundColor": "rgb(255 255 255 / 0.1)",
    "&:enabled:hover": {
      "backgroundColor": "rgb(255 255 255 / 0.2)"
    }
  },
  [ButtonType.SOLID_COLOR]: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(32 0 200 / var(--tw-bg-opacity))",
    "&:enabled:hover": {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(53 17 245 / var(--tw-bg-opacity))",
      "--tw-shadow": "0px 4px 45px 0px #3C00FF",
      "--tw-shadow-colored": "0px 4px 45px 0px var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    },
    "&:disabled": {
      "backgroundColor": "rgb(255 255 255 / 0.1)"
    }
  },
  [ButtonType.SOLID_GRADIENT]: {
    "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
    "--tw-gradient-from": "#320DC4 var(--tw-gradient-from-position)",
    "--tw-gradient-to": "#8544D9 var(--tw-gradient-to-position)",
    "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
    "&:enabled:hover": {
      "opacity": "0.9",
      "--tw-shadow": "0px 4px 45px 0px #5900DE",
      "--tw-shadow-colored": "0px 4px 45px 0px var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    },
    "&:disabled": {
      "backgroundColor": "rgb(255 255 255 / 0.1)"
    }
  },
  [ButtonType.TEXT]: {
    "borderStyle": "none",
    "backgroundColor": "transparent",
    "paddingLeft": "0px",
    "&:enabled:hover": {
      "opacity": "0.5"
    },
    "&:disabled": {
      "borderStyle": "none"
    }
  }
};
const DISABLED_BUTTON_STYLES = {
  outlined: {
    "cursor": "not-allowed",
    "borderStyle": "dashed",
    "borderColor": "rgba(255,255,255,0.20)",
    "opacity": "0.5"
  },
  filled: {
    "cursor": "not-allowed",
    "borderStyle": "dashed",
    "borderColor": "rgba(255,255,255,0.20)",
    "backgroundColor": "rgb(255 255 255 / 0.1)",
    "opacity": "0.5"
  },
  text: {
    "cursor": "not-allowed",
    "opacity": "0.5"
  }
};
const BUTTON_TYPE_DISABLED_STYLES: Record<ButtonType, TwStyle> = {
  [ButtonType.OUTLINED]: DISABLED_BUTTON_STYLES.outlined,
  [ButtonType.OUTLINED_COLOR]: DISABLED_BUTTON_STYLES.outlined,
  [ButtonType.SOLID]: DISABLED_BUTTON_STYLES.filled,
  [ButtonType.SOLID_COLOR]: DISABLED_BUTTON_STYLES.filled,
  [ButtonType.SOLID_GRADIENT]: DISABLED_BUTTON_STYLES.filled,
  [ButtonType.TEXT]: DISABLED_BUTTON_STYLES.text
};

/** Size Styles */

export const BUTTON_SIZE_STYLES: Record<ButtonSize, TwStyle> = {
  [Size.SMALL]: {
    "height": "1.75rem",
    "gap": "0.25rem"
  },
  [Size.MEDIUM]: {
    "height": "2rem",
    "gap": "0.5rem"
  },
  [Size.LARGE]: {
    "height": "2.5rem",
    "gap": "0.75rem"
  }
};
export const BUTTON_SIZE_TEXT_SIZES: Record<ButtonSize, TypographySize> = {
  [Size.SMALL]: TypographySize.X_SMALL,
  [Size.MEDIUM]: TypographySize.SMALL,
  [Size.LARGE]: TypographySize.MEDIUM
};
export const BUTTON_SIZE_ICON_SIZES: Record<ButtonSize, Size> = {
  [Size.SMALL]: Size.SMALL,
  [Size.MEDIUM]: Size.MEDIUM,
  [Size.LARGE]: Size.MEDIUM
};
export const getButtonXPadding = (size: ButtonSize, hasStartIcon: boolean, hasEndIcon: boolean) => {
  const sizeBasePadding = {
    [Size.X_SMALL]: 10,
    [Size.SMALL]: 10,
    [Size.MEDIUM]: 15,
    [Size.LARGE]: 20,
    [Size.X_LARGE]: 20
  };
  const sizeIconPaddingDeduction = {
    [Size.X_SMALL]: 5,
    [Size.SMALL]: 5,
    [Size.MEDIUM]: 5,
    [Size.LARGE]: 10,
    [Size.X_LARGE]: 10
  };
  return {
    left: sizeBasePadding[size] - (hasStartIcon ? sizeIconPaddingDeduction[size] : 0),
    right: sizeBasePadding[size] - (hasEndIcon ? sizeIconPaddingDeduction[size] : 0)
  };
};
export const StyledButton = _styled.button<{
  $fullWidth: boolean;
  $hasEndIcon: boolean;
  $hasStartIcon: boolean;
  $maxWidth: number | TwStyle;
  $size: ButtonSize;
  $type: ButtonType;
  disabled: boolean;
}>(({
  $type,
  $fullWidth,
  $maxWidth,
  $hasEndIcon,
  $hasStartIcon,
  $size,
  disabled
}) => [
// Base styles
{
  "display": "flex",
  "width": "fit-content",
  "alignItems": "center",
  "justifyContent": "center",
  "whiteSpace": "nowrap",
  "borderRadius": "0.25rem",
  "paddingTop": "10px",
  "paddingBottom": "10px"
}, !disabled && {
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(9 90 124 / var(--tw-border-opacity))"
  }
},
// Hover if not disabled, can be overridden by type styles

// Left and right padding based on size and icons
_css`
      padding-left: ${getButtonXPadding($size, $hasStartIcon, $hasEndIcon).left}px;
      padding-right: ${getButtonXPadding($size, $hasStartIcon, $hasEndIcon).right}px;
    `,
// Button size and type styles
BUTTON_SIZE_STYLES[$size], BUTTON_TYPE_STYLES[$type], disabled && BUTTON_TYPE_DISABLED_STYLES[$type], typeof $maxWidth === 'number' ? _css({
  maxWidth: `${$maxWidth}px`
}) : $maxWidth,
// Max width
$fullWidth && {
  "width": "100%"
} // Full width styling
]);