import _styled from "styled-components";
import { withLink } from '../core/hocs';
import ToggleItem, { ToggleOption } from './ToggleItem';
const ToggleNavigatorWrapper = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center"
});
const ToggleNavigatorContainer = _styled.div({
  "display": "inline-flex",
  "width": "100%",
  "maxWidth": "480px",
  "borderRadius": "0.375rem",
  "borderWidth": "0.063rem",
  "borderColor": "rgba(103, 97, 250, 0.37)",
  ">*:not(:last-child)": {
    "borderRightWidth": "0.063rem",
    "borderRightColor": "rgba(103, 97, 250, 0.37)"
  }
});
type ToggleNavigatorProps<ToggleValue extends string> = {
  currentPath: string;
  toggleOptions: ToggleOption<ToggleValue>[];
};
const ToggleNavigator = <ToggleValue extends string,>({
  toggleOptions,
  currentPath
}: ToggleNavigatorProps<ToggleValue>) => <ToggleNavigatorWrapper>
    <ToggleNavigatorContainer>
      {toggleOptions.map((toggleOption, index) => withLink(<ToggleItem<ToggleValue> key={toggleOption.value} isSelected={toggleOption.route === currentPath} toggleIndex={index} toggleOption={toggleOption} />, {
      href: toggleOption.route
    }))}
    </ToggleNavigatorContainer>
  </ToggleNavigatorWrapper>;
export default ToggleNavigator;