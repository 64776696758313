import { OrgPaymentTier } from '@kindo/universal';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LOGIN_RETURN_URL_PARAM, LOGIN_ROUTE, MAGIC_LINK_ROUTE, ROOT_ROUTE, STATUS_ROUTE, VERIFY_ROUTE } from '~/constants/routes.consts';
import { userActions } from '~/redux/reducers/userSlice';
import { nextTrpc } from '~/trpc';

// Routes that the user does not need to be authenticated for
const UNAUTHENTICATED_ROUTES = [LOGIN_ROUTE, MAGIC_LINK_ROUTE, VERIFY_ROUTE, STATUS_ROUTE];

// https://next-auth.js.org/getting-started/client
const AuthWrapper = ({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement | null => {
  const router = useRouter();
  const {
    pathname
  } = router;
  const isOnUnauthenticatedRoute = UNAUTHENTICATED_ROUTES.includes(pathname);
  const redirectToLogin = async () => await router.push(`${LOGIN_ROUTE}?${LOGIN_RETURN_URL_PARAM}=${router.asPath}`);
  const shouldRedirectToStatusPage = process.env.REDIRECT_TO_STATUS_PAGE === 'true' && router.pathname !== STATUS_ROUTE;

  // Redirect user if not authenticated
  const {
    data: session,
    status
  } = useSession({
    required: true,
    onUnauthenticated() {
      if (shouldRedirectToStatusPage) {
        void router.push(STATUS_ROUTE);
        return;
      }

      // Do nothing if the user is already on one of the unauthenticated routes
      if (isOnUnauthenticatedRoute) {
        return;
      }

      // eslint-disable-next-line no-console
      console.warn('Redirecting to login page, session unauthenticated');
      // Redirect to login if the user is not authenticated
      void redirectToLogin();
    }
  });

  // Handle additional redirects,
  // check during the session and route changes
  useEffect(() => {
    if (shouldRedirectToStatusPage) {
      void router.push(STATUS_ROUTE);
      return;
    }
    if (status !== 'authenticated') {
      return;
    }

    // If user needs to verify Microsoft email, redirect to verify
    if (session.user.microsoftOAuthVerificationEmailSent === true && session.user.microsoftOAuthVerified === false && router.pathname !== VERIFY_ROUTE) {
      // eslint-disable-next-line no-console
      console.warn('Redirecting to verify page, Microsoft Oauth verification not verified');
      void router.push(VERIFY_ROUTE);
      return;
    }

    // If microsoftOAuthVerificationEmailSent is false,
    // there was an error when sending the verification email.
    // Send the user back to the login page to restart.
    if (session.user.microsoftOAuthVerificationEmailSent === false && router.pathname !== LOGIN_ROUTE) {
      console.error('Redirecting to login, error occurred during Microsoft Oauth verification');
      void redirectToLogin();
      return;
    }
    if (!session.user.userId && !isOnUnauthenticatedRoute) {
      console.error('No userId found in session, redirecting to login');
      void redirectToLogin();
      return;
    }

    // If user is logged in and on login page, redirect to root
    if (router.pathname === LOGIN_ROUTE && !!session.user.userId) {
      void router.push(ROOT_ROUTE);
    }
  }, [session, router.pathname]);

  // Query user info
  const {
    data
  } = nextTrpc.users.get.useQuery(undefined, {
    enabled: !!session?.user?.userId
  });
  const {
    item: user
  } = data || {};

  // Store user info in redux
  const dispatch = useDispatch();
  useEffect(() => {
    if (user && session) {
      dispatch(userActions.setUserData({
        userId: user.id,
        userEmail: user.email,
        org: {
          id: user.org.id,
          name: user.org.name,
          paymentTier: user.org.paymentTier as OrgPaymentTier ?? OrgPaymentTier.FREE,
          paymentTierConfig: {
            accessSecuritySettings: user.org.paymentTierConfig.accessSecuritySettings,
            maxMembers: user.org.paymentTierConfig.maxMembers,
            maxWorkflows: user.org.paymentTierConfig.maxWorkflows
          },
          workosOrgId: user.org.workosOrgId
        }
      }));
    }
  }, [user, session, dispatch]);

  // Prevent flash of screen before redirecting back to login
  // Unauthenticated routes will have loading status for some reason
  return status === 'loading' && !isOnUnauthenticatedRoute ? null :
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{children}</>;
};
export default AuthWrapper;