import _styled from "styled-components";
import { Input as MuiInput } from '@mui/base/Input';
import { InputAdornment } from '@mui/material';
import { useRef, useState } from 'react';
import { TextIconColor } from '../constants';
import { Icon, Icons } from '../Icon';
import { Typography, TypographyFont, TypographySize, TypographyWeight } from '../Typography';
import { StyledInput, StyledRoot, TextArea, TextFieldType } from './TextField.consts';
export const TextFieldAndErrorContainer = _styled.div<{
  $fullWidth: boolean;
  $width: TwStyle | undefined;
}>(({
  $fullWidth,
  $width
}) => [{
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem"
}, $fullWidth && {
  "width": "100%"
}, $width]);
const StyledInputAdornment = _styled(InputAdornment)({
  "display": "flex",
  "alignItems": "center"
});
interface TextFieldProps {
  onChange: (value: string) => void;
  type: TextFieldType;
  value: string;
  autoFocus?: boolean;
  disabled?: boolean;
  endIcon?: Icon | undefined;
  error?: boolean;
  errorMessage?: string;
  font?: TypographyFont;
  fullWidth?: boolean;
  maxRows?: number | undefined;
  minRows?: number | undefined;
  multiline?: boolean;
  onBlur?: (() => void) | undefined;
  onEnter?: (() => void) | undefined;
  placeholder?: string;
  size?: TypographySize;
  startIcon?: Icon | undefined;
  startIconFocusColor?: TextIconColor;
  textWeight?: TypographyWeight;
  width?: TwStyle;
}
const TextField: React.FC<TextFieldProps> = ({
  onChange,
  type = TextFieldType.DEFAULT,
  value,
  autoFocus = false,
  disabled = false,
  error = false,
  errorMessage,
  maxRows,
  minRows,
  multiline = false,
  onEnter,
  fullWidth = false,
  onBlur,
  placeholder = '',
  size = TypographySize.MEDIUM,
  width,
  endIcon,
  startIcon,
  font = TypographyFont.HEADING,
  startIconFocusColor = TextIconColor.PRIMARY
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const handleBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onEnter) {
      onEnter();
      ref.current?.blur();
    }
  };
  const renderEndAdornment = () => {
    if (endIcon && value === '') {
      return <StyledInputAdornment position="end">
          <Icons color={TextIconColor.PRIMARY} icon={endIcon} />
        </StyledInputAdornment>;
    }
    if (isFocused && value !== '') {
      return <StyledInputAdornment onMouseDown={e => {
        e.preventDefault();
        e.stopPropagation();
        onChange('');
      }} position="end" sx={{
        cursor: 'pointer'
      }}>
          <Icons color={TextIconColor.PRIMARY} icon={Icon.CLOSE} />
        </StyledInputAdornment>;
    }
    return null;
  };
  return <TextFieldAndErrorContainer $fullWidth={fullWidth} $width={width}>
      <Typography font={font} fullWidth size={size}>
        {multiline ? <TextArea $error={error} $type={type} disabled={disabled} maxRows={maxRows || 35} minRows={minRows || 2} onBlur={onBlur} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange?.(e.target.value)} placeholder={placeholder} value={value} /> : <MuiInput autoFocus={autoFocus} disabled={disabled} endAdornment={renderEndAdornment()} inputRef={ref} onBlur={handleBlur} onChange={e => onChange?.(e.target.value)} onFocus={() => setIsFocused(true)} placeholder={placeholder} slotProps={{
        root: {
          // @ts-expect-error
          $error: error,
          $type: type,
          disabled
        },
        input: {
          disabled,
          onKeyDown: handleKeyDown
        }
      }} slots={{
        root: StyledRoot,
        input: StyledInput
      }} startAdornment={startIcon && <StyledInputAdornment position="start" sx={{
        cursor: 'pointer'
      }}>
                  <Icons color={isFocused ? startIconFocusColor : TextIconColor.PRIMARY} icon={startIcon} />
                </StyledInputAdornment>} value={value} />}
      </Typography>
      {errorMessage && <Typography color={TextIconColor.DESTRUCTIVE} size={TypographySize.X_SMALL}>
          {errorMessage}
        </Typography>}
    </TextFieldAndErrorContainer>;
};
export default TextField;