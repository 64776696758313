import { Size } from '../constants';
export type ChipSize = Size.SMALL | Size.MEDIUM;
export const CHIP_SIZE_STYLES: Record<ChipSize, TwStyle> = {
  [Size.SMALL]: {
    "display": "flex",
    "height": "27px",
    "minWidth": "0px",
    "alignItems": "center",
    "gap": "0.625rem",
    "borderRadius": "0.25rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem"
  },
  [Size.MEDIUM]: {
    "display": "flex",
    "height": "2rem",
    "minWidth": "0px",
    "alignItems": "center",
    "gap": "0.625rem",
    "borderRadius": "0.25rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem"
  }
};
export const CHIP_ICON_STYLES = {
  "display": "flex",
  "height": "1rem",
  "width": "1rem",
  "alignItems": "center",
  "justifyContent": "center"
};
export const CHIP_CLOSE_BUTTON_STYLES = {
  "display": "flex",
  "height": "0.875rem",
  "width": "0.875rem",
  "alignItems": "center",
  "justifyContent": "center",
  "borderRadius": "0.125rem",
  "padding": "1px",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms"
};