import _styled from "styled-components";
import { StyledGiantButton } from './GiantButton.styles';
import { TextIconColor, Icon, Icons, ToolTip, ToolTipColor, Typography, TypographyAlignment, TypographySize, TypographyCasing, TypographyFont, Size, GiantButtonType } from '~/components/core';
const StartIconAndTextContainer = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.25rem"
});
const IconContainer = _styled.div({
  "marginLeft": "0.25rem"
});
interface GiantButtonProps {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  tooltip: string;
  type: GiantButtonType;
  disabled?: boolean;
  endIcon?: Icon | undefined;
  startIcon?: Icon | undefined;
}
const GiantButton: React.FC<GiantButtonProps> = ({
  onClick,
  label,
  type,
  disabled = false,
  tooltip,
  endIcon,
  startIcon
}) => <ToolTip color={ToolTipColor.BLUE} content={tooltip ?? ''} offset={[0, 8]}>
    {/* Wrap tooltip in span so it works while button is disabled */}
    <span>
      <StyledGiantButton $type={type} disabled={disabled} onClick={onClick}>
        <StartIconAndTextContainer>
          {startIcon && <IconContainer>
              <Icons color={TextIconColor.PRIMARY} icon={startIcon} size={Size.SMALL} />
            </IconContainer>}
          <Typography alignment={TypographyAlignment.CENTER} casing={TypographyCasing.UPPERCASE} color={TextIconColor.PRIMARY} font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL}>
            {label}
          </Typography>
        </StartIconAndTextContainer>
        {endIcon && <IconContainer>
            <Icons color={TextIconColor.PRIMARY} icon={endIcon} />
          </IconContainer>}
      </StyledGiantButton>
    </span>
  </ToolTip>;
export default GiantButton;