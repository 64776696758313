import _styled from "styled-components";
import { Typography } from '../Typography';
import { TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '../Typography/Typography.consts';
import { BadgeState } from './Badge.consts';
const StyledBadge = _styled.div<{
  $state: BadgeState;
}>(({
  $state
}) => [
// Base style
{
  "display": "inline-flex",
  "width": "fit-content",
  "alignItems": "center",
  "borderRadius": "0.125rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.125rem",
  "paddingBottom": "0.125rem"
},
// Badge state
$state === BadgeState.ACTIVE && {
  "backgroundColor": "rgb(255 255 255 / 0.2)"
}, $state === BadgeState.INACTIVE && {
  "backgroundColor": "rgb(255 255 255 / 0.1)"
}, $state === BadgeState.STRONG && {
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(84 254 209 / var(--tw-border-opacity))",
  "backgroundColor": "rgb(255 255 255 / 0.1)"
}, $state === BadgeState.MODERATE && {
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(253 235 144 / var(--tw-border-opacity))",
  "backgroundColor": "rgb(255 255 255 / 0.1)"
}, $state === BadgeState.WEAK && {
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(255 11 80 / var(--tw-border-opacity))",
  "backgroundColor": "rgb(255 255 255 / 0.1)"
}]);
interface BadgeProps {
  label: string;
  state?: BadgeState;
}
const Badge = ({
  label,
  state = BadgeState.INACTIVE
}: BadgeProps) => <StyledBadge $state={state}>
    <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM}>
      {label}
    </Typography>
  </StyledBadge>;
export default Badge;