import _styled from "styled-components";
import { TextIconColor, Icon, Icons, Typography, TypographyWeight } from '..';
import { Button, ButtonType } from '~/components/core';

// https://mui.com/material-ui/react-alert/
export enum BannerType {
  INFO = 'INFO',
  WARNING = 'WARNING',
}

// TODO: Warning icon
const BANNER_TYPE_ICON_STYLE: Record<BannerType, Icon> = {
  [BannerType.WARNING]: Icon.BOLT,
  [BannerType.INFO]: Icon.BOLT
};
const BANNER_TYPE_BACKGROUND_COLOR_STYLE: Record<BannerType, TwStyle> = {
  [BannerType.WARNING]: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(250 204 21 / var(--tw-bg-opacity))"
  },
  [BannerType.INFO]: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(59 130 246 / var(--tw-bg-opacity))"
  }
};
const BANNER_TYPE_TEXT_COLOR_STYLE: Record<BannerType, TextIconColor> = {
  [BannerType.WARNING]: TextIconColor.PRIMARY,
  [BannerType.INFO]: TextIconColor.PRIMARY
};
const BannerContent = _styled.div<{
  $bannerType: BannerType;
}>(({
  $bannerType
}) => [{
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "2rem",
  "padding": "0.5rem"
}, BANNER_TYPE_BACKGROUND_COLOR_STYLE[$bannerType]]);
const IconAndTextWrapper = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem"
});
interface BannerRedirectButtonProps {
  href: string;
  label: string;
}
interface BannerProps {
  text: string;
  type: BannerType;
  redirectButton?: BannerRedirectButtonProps;
}
export const Banner = ({
  text,
  type,
  redirectButton
}: BannerProps) => {
  const icon = BANNER_TYPE_ICON_STYLE[type];
  const textColor = BANNER_TYPE_TEXT_COLOR_STYLE[type];
  return <BannerContent $bannerType={type}>
      <IconAndTextWrapper>
        <Icons color={textColor} icon={icon} />
        <Typography color={textColor} weight={TypographyWeight.MEDIUM}>
          {text}
        </Typography>
      </IconAndTextWrapper>
      {redirectButton && <Button href={redirectButton.href} label={redirectButton.label} type={ButtonType.SOLID} />}
    </BannerContent>;
};
export default Banner;