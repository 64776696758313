import {
  toast as reactToast,
  ToastContainer as ReactToastContainer
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import { Icons, TextIconColor, Icon, Size } from '~/components/core';

export enum ToastType {
  ERROR = 'ERROR',
  REFRESH = 'REFRESH',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING'
}

// Constants
const TOAST_TYPE_STYLES = {
  [ToastType.SUCCESS]: 'bg-highlight text-primary-dark',
  [ToastType.ERROR]: 'bg-fuchsia-error text-primary-dark',
  [ToastType.WARNING]: 'bg-yellow-warning text-primary-dark',
  [ToastType.REFRESH]: 'bg-primary-bright text-white'
};

const TOAST_TYPE_ICON_COLORS = {
  [ToastType.SUCCESS]: TextIconColor.BLACK,
  [ToastType.ERROR]: TextIconColor.BLACK,
  [ToastType.WARNING]: TextIconColor.BLACK,
  [ToastType.REFRESH]: TextIconColor.PRIMARY
};

const TOAST_TYPE_ICONS = {
  [ToastType.SUCCESS]: Icon.CONFIRM,
  [ToastType.ERROR]: Icon.ERROR,
  [ToastType.WARNING]: Icon.WARNING,
  [ToastType.REFRESH]: Icon.RELOAD
};

// Styled components
const ToastWrapper = styled.div`
  height: 64px;
  min-width: fit-content;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1.5rem;
`;

const MessageContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
`;

const Message = styled.span`
  white-space: nowrap;
  font-size: 1rem;
`;

const CloseButton = styled.button`
  margin-left: 1.5rem;
  &:hover {
    opacity: 0.8;
  }
`;

// Removes default styles from react-toastify
const BaseToastContainer = styled(ReactToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress'
})`
  &&&.Toastify__toast-container {
    padding: 0;
    width: auto;
  }

  .Toastify__toast {
    margin: 0 0 8px 0;
    padding: 0;
    background: none;
    box-shadow: none;
    border: none;
    width: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
    background: none;
    width: auto;
  }

  .Toastify__close-button {
    display: none;
  }
`;

export const Toast = () => (
  <BaseToastContainer
    closeButton={false}
    hideProgressBar
    position="top-right"
  />
);

interface CustomToastProps {
  closeToast: (() => void) | undefined;
  message: string;
  type: ToastType;
}

const CustomToast = ({ message, type, closeToast }: CustomToastProps) => (
  <ToastWrapper className={TOAST_TYPE_STYLES[type]}>
    <ContentContainer>
      <MessageContainer>
        <Icons
          color={TOAST_TYPE_ICON_COLORS[type]}
          icon={TOAST_TYPE_ICONS[type]}
          size={Size.LARGE}
        />
        <Message>{message}</Message>
      </MessageContainer>
      {closeToast && (
        <CloseButton
          aria-label="Close notification"
          onClick={() => closeToast()}
          type="button"
        >
          <Icons
            color={TOAST_TYPE_ICON_COLORS[type]}
            icon={Icon.CLOSE}
            size={Size.MEDIUM}
          />
        </CloseButton>
      )}
    </ContentContainer>
  </ToastWrapper>
);

const getAutoClose = (type: ToastType, autoClose: boolean): number | false => {
  if (!autoClose) {
    return false;
  }

  switch (type) {
    case ToastType.ERROR:
      return 5000;
    case ToastType.REFRESH:
      return 7000;
    case ToastType.SUCCESS:
    case ToastType.WARNING:
    default:
      return 3000;
  }
};

export interface ToastArgs {
  message: string;
  type: ToastType;
  autoClose?: boolean;
  closeButton?: boolean;
  delay?: boolean;
  onClick?: () => void;
  preventClose?: boolean;
}

const useToast = () => {
  const enqueueToast = ({
    type,
    message,
    delay,
    autoClose = true,
    preventClose = false,
    onClick = () => {},
    closeButton = false
  }: ToastArgs) => {
    const showCloseButton = closeButton || (!autoClose && !preventClose);

    reactToast(
      ({ closeToast }) => (
        <CustomToast
          closeToast={showCloseButton ? closeToast : undefined}
          message={message}
          type={type}
        />
      ),
      {
        draggablePercent: 60,
        autoClose: getAutoClose(type, autoClose),
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: showCloseButton,
        onClick,
        position: 'top-right',
        delay: delay ? 2000 : 0
      }
    );
  };

  return { enqueueToast };
};

export default useToast;
