import _styled from "styled-components";
import React, { useState } from 'react';
import { Icon, Select, TextField, TextFieldType } from '~/components/core';

// Options that can be selected for a single filter
export type FilterOption = {
  label: string;
  value: string;
};
interface Filter<FilterKey extends string> {
  defaultLabel: string;
  filterKey: FilterKey; // e.g. FilesFilter.FILE_TYPE
  options: FilterOption[];
  placeholder: string;
}

// FiltersValues are the values of ALL the filters. E.g. { FilesFilter.FILE_TYPE: 'csv' }
export type FiltersValues<K extends string> = Record<K, string | undefined>;
const FilterContainer = _styled.div({
  "display": "flex",
  "flexWrap": "wrap",
  "alignItems": "center",
  "gap": "0.75rem"
});
const SelectContainer = _styled.div({
  "display": "flex",
  "flexGrow": "1",
  "flexDirection": "row",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-x-reverse": "0",
    "marginRight": "calc(0.5rem * var(--tw-space-x-reverse))",
    "marginLeft": "calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))"
  }
});
export interface FiltersProps<FilterKey extends string> {
  filters: Filter<FilterKey>[];
  filtersValues: FiltersValues<FilterKey>;
  searchValue: string;
  setFilters: (newFilterValues: FiltersValues<FilterKey>) => void;
  children?: React.ReactNode;
  searchPlaceholder?: string;
  setSearchValue?: (search: string) => void;
}

/**
 * K - the type of
 */
const Filters = <FilterKey extends string,>({
  filters,
  searchValue,
  filtersValues,
  setFilters,
  setSearchValue,
  searchPlaceholder,
  children
}: FiltersProps<FilterKey>) => {
  const [innerValue, setInnerValue] = useState(searchValue);
  const handleSetPartialFilter = (filterKey: FilterKey, newValue: string | number | undefined) => setFilters({
    ...filtersValues,
    [filterKey]: newValue
  });
  const handleSearchChange = (searchText: string) => {
    setSearchValue?.(searchText.trim());
    setInnerValue(searchText);
  };
  return <FilterContainer>
      {setSearchValue && <TextField onChange={handleSearchChange} placeholder={searchPlaceholder || 'SEARCH'} startIcon={Icon.SEARCH} type={TextFieldType.DEFAULT} value={innerValue} />}
      <SelectContainer>
        {filters.map(({
        defaultLabel,
        options,
        placeholder,
        filterKey
      }) => {
        const defaultOption: FilterOption | undefined = defaultLabel ? {
          label: defaultLabel,
          value: ''
        } : undefined;
        const optionsWithDefault = defaultOption ? [defaultOption, ...options] : options;
        return <Select key={filterKey} onChange={value => handleSetPartialFilter(filterKey, value ?? undefined)} options={optionsWithDefault} placeholderLabel={placeholder} value={filtersValues[filterKey] ?? ''} />;
      })}
        {children}
      </SelectContainer>
    </FilterContainer>;
};
export default Filters;