import _styled from "styled-components";
import Tippy from '@tippyjs/react';
export enum ToolTipColor {
  BLUE = 'BLUE',
  RED = 'RED',
  WHITE = 'WHITE',
}

// https://atomiks.github.io/tippyjs/v6/all-props/#placement
export enum TooltipPlacement {
  AUTO = 'auto',
  AUTO_END = 'auto-end',
  AUTO_START = 'auto-start',
  BOTTOM = 'bottom',
  BOTTOM_END = 'bottom-end',
  BOTTOM_START = 'bottom-start',
  LEFT = 'left',
  LEFT_END = 'left-end',
  LEFT_START = 'left-start',
  RIGHT = 'right',
  RIGHT_END = 'right-end',
  RIGHT_START = 'right-start',
  TOP = 'top',
  TOP_END = 'top-end',
  TOP_START = 'top-start',
}
const TOOL_TIP_COLOR_STYLES: Record<ToolTipColor, TwStyle> = {
  [ToolTipColor.RED]: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(254 226 226 / var(--tw-bg-opacity))",
    "--tw-text-opacity": "1",
    "color": "rgb(153 27 27 / var(--tw-text-opacity))"
  },
  [ToolTipColor.BLUE]: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))",
    "--tw-text-opacity": "1",
    "color": "rgb(255 255 255 / var(--tw-text-opacity))"
  },
  [ToolTipColor.WHITE]: {
    "borderWidth": "1px",
    "borderColor": "#8CAEBA33",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
    "backgroundImage": "linear-gradient(to bottom right, var(--tw-gradient-stops))",
    "--tw-gradient-from": "#8CAEBA33 var(--tw-gradient-from-position)",
    "--tw-gradient-to": "#f9fafb var(--tw-gradient-to-position)",
    "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
    "padding": "0.75rem",
    "textAlign": "left",
    "fontSize": "0.75rem",
    "lineHeight": "1rem",
    "fontStyle": "italic",
    "--tw-text-opacity": "1",
    "color": "rgb(0 0 0 / var(--tw-text-opacity))",
    "--tw-shadow": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    "--tw-shadow-colored": "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    "--tw-backdrop-blur": "blur(4px)",
    "WebkitBackdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
    "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)"
  }
};
const StyledTippy = _styled(Tippy)<{
  $color: ToolTipColor;
}>(({
  $color
}) => [{
  "maxWidth": "20rem",
  "whiteSpace": "pre-line",
  "borderRadius": "0.5rem",
  "padding": "0.5rem",
  "textAlign": "center",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem"
}, TOOL_TIP_COLOR_STYLES[$color]]);
const ClickableContent = _styled.div({
  "cursor": "pointer",
  "&:hover": {
    "opacity": "0.85"
  }
});
interface ToolTipProps {
  children: React.ReactElement;
  content: string | undefined;
  color?: ToolTipColor;
  disabled?: boolean;
  interactive?: boolean;
  offset?: [number, number];
  onClick?: (() => void) | undefined;
  placement?: TooltipPlacement;
}
const ToolTip: React.FC<ToolTipProps> = ({
  content,
  children,
  disabled = false,
  interactive = false,
  placement = TooltipPlacement.TOP,
  offset = [0, 1],
  onClick,
  color = ToolTipColor.BLUE
}) => <StyledTippy $color={color} content={onClick ? <ClickableContent onClick={onClick}>{content}</ClickableContent> : content} disabled={disabled || !content} duration={[null, 0]} interactive={interactive} offset={offset} placement={placement}>
    {children}
  </StyledTippy>;
export default ToolTip;