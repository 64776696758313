import { css as _css } from "styled-components";
import _styled from "styled-components";
import { Typography, TypographyFont, TypographySize, TypographyWeight, TextIconColor } from '~/components/core';
export const HistoryContainer = _styled.div`
  ${{
  "position": "relative",
  "display": "flex",
  "height": "100%",
  "flexDirection": "column",
  "gap": "1rem",
  "overflow": "auto",
  "paddingBottom": "6rem",
  "paddingTop": "0.75rem"
}}
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const StyledGroupTitle = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem"
});
export const GroupContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.125rem"
});
const GroupTitleHR = _styled.hr({
  "height": "1px",
  "borderStyle": "none",
  "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
  "--tw-gradient-from": "#9694B5 var(--tw-gradient-from-position)",
  "--tw-gradient-to": "rgba(39,40,62,0) var(--tw-gradient-to-position)",
  "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)"
});
interface GroupTitleProps {
  title: string;
}
export const GroupTitle = ({
  title
}: GroupTitleProps) => <StyledGroupTitle>
    <Typography color={TextIconColor.PRIMARY} font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL} weight={TypographyWeight.BOLD}>
      {title}
    </Typography>
    <GroupTitleHR />
  </StyledGroupTitle>;
export const HistoryItem = _styled.div<{
  $content?: string;
  $hoverContent?: string;
}>(({
  $content,
  $hoverContent
}) => [{
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem",
  "textAlign": "left",
  ">a>span": {
    "display": "block",
    "overflow": "hidden",
    "textOverflow": "ellipsis",
    "whiteSpace": "nowrap",
    "transitionProperty": "opacity,width,color",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms"
  },
  "&:hover>a>span": {
    "--tw-text-opacity": "1",
    "color": "rgb(255 255 255 / var(--tw-text-opacity))",
    "opacity": "1"
  }
}, _css`
    & > span > span::before {
      ${$content && `content: '${$content}';`}
    }
    &:hover > span > span::before {
      ${$hoverContent && `content: '${$hoverContent}';`}
    }
    & > a > span {
      width: ${$content ? '240px' : '300px'};
      ${$content && '&:hover { width: 200px; }'}
    }
  `]);